// -----------------------------------------------------------------------------
// This file contains all styles related to the table component.
// -----------------------------------------------------------------------------

.module--partner {
    margin-bottom: calculateRem(50px);
    .module-caption {
        color: $primary;
        margin-top: calculateRem(15px);
        a {
            color: $primary;
        }
        h2 {
            //@include fontSize(18px);
            @include fontSize(20px);
            @include lineHeight(32px);  
            //@include font-family-open-sans-semibold();
            @include font-family-meta-semibold();
        }
        * {
            margin-bottom: 0;
        }
    }
    .module-button {
        margin-top: calculateRem(10px);
        span.arrow > svg,
        span.arrow > span > svg {
            path {
                //fill: $btn-secondary;
                fill: $custom-primary;
            } 
        } 
    } 
}