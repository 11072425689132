// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

$text-primary: $custom-primary;
$text-secondary: $custom-secondary;
$btn-primary: $custom-primary;
$btn-secondary: $white;

$color-pretitle: #AAACB0;
$bg-color-location: rgba(205, 21, 52, 1);

$border-secondary: $custom-secondary;

$blue: #5c8bac;
$bg-color-icon: #8C0550;
$bg-color-widget: $primary;


$bg-grey-light: #F5F5F5;
$bg-grey-dark: $primary;

$grey-light: #AAACB0;

$bg-dropdown-submenu-career: #8d044f;


$border-grey-light: #AAACB0;
$border-grey-light-timeline: #ECE9E4;
$border-grey-light-events: #D9D9D9;

$news-tags-border-color: $primary;
$news-tags-text-color: $primary;

$grey-dark: $primary;

$bg-linear-gradient: linear-gradient(88.19deg, #FFFFFF 1.57%, #CD1432 56.69%, #8C0550 100%);
$bg-linear-gradient-2: linear-gradient(88.14deg, #CD1432 0%, #8C0550 61.54%);     
$bg-linear-gradient-3: linear-gradient(257.74deg, #CD1534 0%, #8D044E 75.52%);
$bg-linear-gradient-4: linear-gradient(88.14deg, #CD1432 0%, #8C0550 61.54%);


//$media-gradient: rgba(59, 74, 89, 1);
$media-gradient-hover: linear-gradient(55.3deg, #CD1534 0%, #8D044E 100%);

$bg-search-modal: linear-gradient(88.14deg, #CD1432 0%, #8C0550 61.54%);

$bg-dropdown-menu: #87054b;



//$text-primary: $custom-primary;
//$text-secondary: $custom-secondary;
//$btn-primary: $custom-primary;
//$btn-secondary: $custom-secondary;

//$color-pretitle: #5C8BAC;
//$bg-color-location: #5C8BAC;

//$border-secondary: $custom-secondary;

//$blue: #5c8bac;
//$bg-color-icon: #5c8bac;
//$bg-color-widget: #5c8bac;

//$bg-grey-light: #F5F5F5;
//$bg-grey-dark: $primary;

//$grey-light: #D9D9D9;

//$border-grey-light: #D9D9D9;
//$border-grey-light-timeline: #ECE9E4;

//$news-tags-border-color: #979797;
//$news-tags-text-color: #ACACAC;

//$grey-dark: $primary;

//$bg-linear-gradient: linear-gradient(90deg, #FFFFFF 0%, #5C8BAC 100%);              // Containertrenner
//$bg-linear-gradient-2: linear-gradient(87.96deg, #004780 19.07%, #5C8BAC 100%);     // Counter
//$bg-linear-gradient-3: linear-gradient(219.47deg, #004780 12.55%, #5C8BAC 100%);    // Quote/Zitat
//$bg-linear-gradient-4: linear-gradient(87.96deg, #004780 19.07%, #5C8BAC 100%);     // Newsfilter, Newsarchivfilter

//$media-gradient: radial-gradient(50% 50% at 50% 50%, rgba(0, 15, 27, 0) 0%, rgba(0, 15, 27, 0.7) 100%);    
//$media-gradient-hover: radial-gradient(50% 50% at 50% 50%, rgba(#5C8BAC, 0.8) 0%, rgba(#5C8BAC, 0.8) 100%);    

//$bg-search-modal: linear-gradient(87.96deg, #004780 19.07%, #5C8BAC 100%);

//$bg-dropdown-menu: #004875;
