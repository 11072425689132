a.skip-to-content {
    position: absolute;
    z-index: 9999999;
    background: $custom-primary;
    left: 50%;
    display: inline-block;
    top: 1rem;
    padding: 1rem;
    border: solid 1px $custom-primary;
    color: $white;
    overflow: hidden;
    box-shadow: $focus-box-shadow;
    transform: translateX(-50%) translateY(-10rem);
    transition: transform .3s ease;


    @include fontSize(20px);
    @include lineHeight(22px);  
    //@include font-family-open-sans-medium();
    @include font-family-meta-medium();

    &:hover {
        color: $white;
    }

    &:focus-visible, &:focus  {
        transform: translateX(-50%) translateY(0);
        box-shadow: $focus-box-shadow;       
    }
}
