// -----------------------------------------------------------------------------
// This file contains all styles related to the icons component.
// -----------------------------------------------------------------------------
.module--icons {
    margin-top: calculateRem(50px);
    margin-bottom: 0 !important;

    @include media-breakpoint-down(md) {
        margin-top: 0;
        margin-bottom: calculateRem(50px) !important;
    }

    .module-icon {
        position: relative;
        display: inline-block;
        width: 96px;
        height: 96px;    

        &:before {
            content: '';
            width: 96px;
            height: 96px;
            border-radius: 50%;
            display: block;
            background-color: $bg-color-icon;
            opacity: 0.1;
            margin-left: calculateRem(12px);
        }
        
        img, svg {
            position: absolute;
            bottom: 0;
            width: auto;
            max-width: 100%;
            height: 65px;
            display: block;
            text-align: left;
        } 
    }  
    .module-icon + div {
        margin-top: calculateRem(25px);  
    }      
    .module-title + .module-caption {
        margin-top: calculateRem(20px);  
    }
}

.accordion-body .module--icons {
    margin-top: 0;
}