// -----------------------------------------------------------------------------
// This file contains all styles related to the event component.
// -----------------------------------------------------------------------------
.module--event {
    .module-event {
        padding: calculateRem(18px) calculateRem(15px);
        .title {
            @include fontSize(20px);
            @include lineHeight(32px);  
            @include font-family-meta-bold();    
        }
        .module-button {
            margin-top: 0;
        }        
        border-bottom: 1px solid $border-grey-light-events;
        &:first-child {
           border-top: 1px solid $border-grey-light-events;
        }
    }
}

.module--text + .module--event {
    margin-top: calculateRem(100px);
    @include media-breakpoint-down(md) {
        margin-top: calculateRem(50px);
    }    
}

