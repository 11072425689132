// -----------------------------------------------------------------------------
// This file contains all styles related to the zitat component.
// -----------------------------------------------------------------------------

.module--quote {
    position: relative;
    padding: calculateRem(70px) $grid-gutter-width calculateRem(70px) $grid-gutter-width;

    &:before {
        content: "";
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: $bg-linear-gradient-3;
        transform: rotate(-180deg);
        z-index: -1;
    }

    .module-title * {
        @include fontSize(37px);
        @include lineHeight(42px);  
        @include font-family-meta-bold();
        span {
            @include font-family-meta-normal();
        }   
        @include media-breakpoint-down(xl) {
            @include fontSize(25px);
            @include lineHeight(35px); 
        }
        @include media-breakpoint-down(md) {
            //@include fontSize(28px);
            //@include lineHeight(38px);  
        }           
    }
    .module-caption {
        @include fontSize(17px);
        @include lineHeight(42px);  
        @include font-family-meta-normal();
    }

    .module-title + .module-caption {
        margin-top: calculateRem(28px);
    }

    .module-title *:before {
        content: '“';
        position: relative;
        display: inline-block;
    }
    .module-title *:after {
        content: '”';
        position: relative;
        display: inline-block;
    }    
    
}