.bootstrap-select {
  width: 100% !important;
  border: 0px !important;
  padding: 0 0 !important;
  
  .dropdown-toggle {
    padding: 0 10px !important;
    border-radius: 0;
    border: 1px solid $white !important;
    margin: 0 0;

    @include fontSize(20px);
    @include lineHeight(38px);  
    //@include font-family-open-sans-regular();
    @include font-family-meta-normal();
    color: $body-color !important;
    display: block;
    background: transparent !important;

    * {
      padding: 0 0 !important;
    }

    &:focus {
      outline: none !important;
      //box-shadow: none;
      //border-color: $custom-primary !important;
      color: $body-color !important;
      background: transparent !important;
      box-shadow: $focus-box-shadow !important;
    }  
      // custom arrow
    &:after {
      display: none;
    } 
  } 
  .dropdown-menu {
    margin: 0;
    padding: 0;
    border: 0 !important;
    //@include fontSize(18px);
    //@include lineHeight(38px);  
    //@include font-family-open-sans-regular();
    //color: $body-color !important;
    border: 0;
    border-radius: 0;
    max-height: calculateRem(230px) !important;

    > div {
      padding: 0 0;
    }

    li {
      padding: 0 0;
      border: 0;
      a {
        padding: 0 0;
        outline: none;
        @include fontSize(20px);
        @include lineHeight(38px);  
        //@include font-family-open-sans-regular();
        @include font-family-meta-normal();
        color: $body-color !important;                
        color: #000 !important;

        &.active, &:active, &:hover, &:focus {
          background-color: transparent;
          //color: $custom-primary !important;
          //@include font-family-open-sans-semibold();
        } 
        &:hover {
          color: $custom-primary !important;
          //@include font-family-open-sans-semibold();
          @include font-family-meta-semibold();
        }           
      }
      &:first-child {
        //display: none;
      }
    }
    &.show {
      z-index: 11000;
    }
  }

  &.parsley-error {

  }
}

.filter .bootstrap-select .dropdown-toggle {
  &.bs-placeholder * {
    //color: transparent !important;
  }
}