// -----------------------------------------------------------------------------
// This file contains all styles related to the caption component.
// -----------------------------------------------------------------------------

.social-media-icons {
    li {
        padding: 0 0;
    }
    li + li {
        margin-left: 15px;
        @include media-breakpoint-down(md) {
            margin-left: 30px;
        }
    }
    a  {
        color: $custom-primary;
        @include fontSize(15px);
        @include media-breakpoint-down(md) {
            @include fontSize(20px);
        }
    }
}

.module--widget .social-media-icons a { 
    @include fontSize(20px);
}