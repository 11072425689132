.scroll-to-top {
    font-size: 20px;
    text-align: center;
    color: #fff;
    text-decoration: none;
    //position: fixed;
    position: absolute;
    bottom: calculateRem(60px);
    right: calculateRem(138px);
    opacity: 1 !important;
	display: block;
	visibility: hidden;
	visibility: visible;
    border-radius: 50%;
    width: calculateRem(60px);
    height: calculateRem(60px);
    z-index: 9999;
    outline: 0;
    transition: $transition-base;
    transform: rotate(-90deg);
    //spointer-events: none;
    svg {
        width: 100%;
        height: 100%;
        path {
            fill: $white;
        } 
    }
    &.is--visible {
        opacity: 1 !important;
        transform: translateY(0) rotate(-90deg);
        //visibility: visible;
        //pointer-events: all;
    }
    &:hover { }
    @include media-breakpoint-down(xl) {
        right: calculateRem(25px);
    }
    @include media-breakpoint-down(md) {
        width: calculateRem(49px);
        height: calculateRem(49px);
        right: calculateRem(24px);
        span.arrow.large {
            width: 49px;
            height: 49px;
        }
        span.arrow.large > svg {
            width: 19px;
        }        
    }
}
body.is--menu-open {
    .scroll-to-top {
        z-index: -1;
    }
}