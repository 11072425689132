// -----------------------------------------------------------------------------
// This file contains all styles related to the card component.
// -----------------------------------------------------------------------------

.module--pagination {

    width: 100%;
    padding: calculateRem(30px) 0;
    border-top: 1px solid $border-grey-light;
    border-bottom: 1px solid $border-grey-light;

    ul {
        flex-direction: row;
        margin: 0 0;
        li {
            &:first-child, &:last-child {
                padding: 0 10px;
            }

            .module-button {
                margin-top: 0;
            }

            padding: 0 calculateRem(15px);
            a {
                text-decoration: none;
                margin-top: 0 !important;

            }
            * {
                //@include fontSize(18px);
                //@include lineHeight(30px);  
                @include fontSize(20px);
                @include lineHeight(30px);                  
                color: $primary;
                @include font-family-meta-normal();             
            }
            span {
                @include font-family-meta-bold();
                color: $text-primary; 
            }   

            &.current,
            &.current a {
                @include font-family-meta-bold();
                color: $text-primary;
            }

            &.previous .arrow {
                rotate: 180deg;
            }
            &.previous, &.next {
                span:not(.arrow):not(.ellipse) {
                    @include media-breakpoint-down(md) {
                        display: none;
                    }
                }
            }
            span.arrow > svg,
            span.arrow > span > svg {
                path {
                    //fill: $btn-secondary;
                    fill: $custom-primary;
                } 
            }              
        }
    }

    &.tx-indexedsearch-pagination {
        padding: calculateRem(20px) 0;
        ul {
            * {
                color: $text-primary;          
            }
            li .current a {
                color: $text-primary;
            }
        }
    }
}
