body {
   //@include font-family-open-sans-regular();
   //@include fontSize(18px);
   @include font-family-meta-normal();
   @include fontSize(20px);
   @include lineHeight(32px);  
}

h1, h2, h3, h4, h5 {
    margin-bottom: 0;
    //@include font-family-open-sans-bold();
    @include font-family-meta-bold();
    color: $primary;
    word-break: break-word;
    hyphens: auto;
    a {
       text-decoration: none;
    }
}

.module-caption,
.news .article .module-caption {
   h1, h2, h3, h4, h5, h6 {
      @include fontSize(30px);
      @include lineHeight(30px);  
      //@include font-family-poppins-semibold();
      @include font-family-meta-bold();
      margin-bottom: 1rem;  
   }
}
 
b, strong {
   //@include font-family-open-sans-bold();
   @include font-family-meta-bold();
}

p.small {
   @include fontSize(15px);
   @include lineHeight(25px);
}

span.show_at, span.show_dot {
   padding-right: 0 !important;
   position: relative !important;
   margin-left: auto !important;
   width: auto !important;
   height: auto !important;
}
span.show_at:before {
   content: "@";
}
span.show_dot:before {
   content: ".";
}

p.title {
   color: $primary;
}

.title {
   @include fontSize(45px);
   @include lineHeight(55px);  
   //@include font-family-poppins-semibold();
   @include font-family-meta-bold();
   margin-bottom: 0;
   span {
      //@include font-family-poppins-light();
      @include font-family-meta-normal();
   }
   @include media-breakpoint-down(xl) {
      @include fontSize(40px);
      @include lineHeight(50px);  
   }
   @include media-breakpoint-down(lg) {
      @include fontSize(35px);
      @include lineHeight(45px);  
   }   
   @include media-breakpoint-down(md) {
      @include fontSize(28px);
      @include lineHeight(38px);  
   }
}

// icon-box

.title.medium {
   //@include fontSize(33px);
   //@include lineHeight(43px);  
   //@include fontSize(32px);
   //@include lineHeight(42px);  
   //@include font-family-poppins-semibold();
   @include font-family-meta-bold();
   @include fontSize(37px);
   @include lineHeight(42px); 
   span {
      //@include font-family-poppins-light();
      @include font-family-meta-normal();
   }  
   @include media-breakpoint-down(md) {
      @include fontSize(28px);
      @include lineHeight(38px);  
   }    
}

.title.small {
   //@include fontSize(33px);
   //@include lineHeight(43px);  
   //@include fontSize(30px);
   //@include lineHeight(40px);  
   //@include font-family-poppins-semibold();
   @include font-family-meta-bold();
   @include fontSize(34px);
   @include lineHeight(40px);     
   span {
      //@include font-family-poppins-light();
      @include font-family-meta-normal();
   }   
   @include media-breakpoint-down(xl) {
      @include fontSize(25px);
      @include lineHeight(35px); 
   }
   @include media-breakpoint-down(md) {
      //@include fontSize(28px);
      //@include lineHeight(38px);  
   }   
}

.title.xsmall {
   @include fontSize(26px);
   @include lineHeight(36px);  
   //@include font-family-poppins-semibold();
   @include font-family-meta-bold();
   span {
      //@include font-family-poppins-light();
      @include font-family-meta-normal();
   }   
   @include media-breakpoint-down(xl) {
      @include fontSize(25px);
      @include lineHeight(35px); 
   }
   @include media-breakpoint-down(md) {
   }   
}

//------------------
// smaller


// news ??? 
.title.small---x {
   @include fontSize(22px);
   @include lineHeight(35px);  
   //@include font-family-open-sans-bold();
   @include font-family-meta-bold();
}
//-- infobox ???
.title.smallest--x {
   @include fontSize(17px);
   @include lineHeight(27px);  
   //@include font-family-open-sans-bold();
   @include font-family-meta-bold();
}


