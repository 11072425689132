.search-modal {
    
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -10;
    transition: all 1s ease;
    background: transparent !important;



    .modal-bg {
        position: absolute;            
        left: 0;
        bottom: 0;
        mix-blend-mode: multiply;
        z-index: 10;
        opacity: 0;
        transition: $transition-base;
        transition-delay: 1.6s;

        svg {
            width: calculateRem(570px);
            height: auto; 
        }
    }


    .modal-overlay {
        position: fixed;
        width: 224vw;
        height: 224vw;
        top: calc(90px - 112vw);
        right: calc(100px - 112vw);
        z-index: 3;
        display: block;
        //background-color: rgba(14, 14, 14, 0.9);
        background-color: rgba(255, 255, 255, 0.9);
        border-radius: 50%;
        transform: scale(0);
        -webkit-transform-origin: center;
        transform-origin: center;
        transition: transform 0.8s ease-in-out;
        transition-delay: 0.3s;       
    }

    .modal-content {
        background: transparent !important;
    }

    .modal-dialog {
        opacity: 0;
        //transition: opacity 0.2s linear;
        transition: $transition-base;
        transition-delay: 1.6s;
        z-index: 9999;

        .modal-bg--x {
            position: absolute;            
            left: 0;
            bottom: 0;
            mix-blend-mode: multiply;
            svg {
                width: calculateRem(570px);
                height: auto;
            }
        }

    }
 
    &.show {
        z-index: 9999;
        .modal-overlay {
            transform: scale(1);
            @include media-breakpoint-down(xl) {
                transform: scale(1.6);
            }
            @include media-breakpoint-down(sm) {
                transform: scale(1.9);
            }
            transition-delay: 0s;
            -webkit-transition-delay: 0s;
            opacity: 1;
            transition: transform 1.6s cubic-bezier(0.4, 0, 0, 1); 
            background: $bg-search-modal;
        }

        .modal-bg {
            opacity: 1; 
        }

        .modal-dialog {  
            opacity: 1; 

            .modal-bg-x {
                position: absolute;            
                left: 0;
                bottom: 0;
                mix-blend-mode: multiply;

                &:before {
                    background:  url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1NzEgODE2Ij48cGF0aCBkPSJNMzk0LjI2MiA1MTYuOTE2QzE4My4zNjYgMjc5Ljg5MyA4LjkyOCAyNTEuODk0LTUxLjkyOSAyNjcuNTIzYy0yMjAuNzM2IDU0Ljc3OS0zODAuMTk5IDM0NS40NTQtMzk5Ljk3NiA0ODAuMDM1czUxLjc2NyAzMDQuNjgyIDI1NC4wODEgMzQzLjAxMkM0LjQ5IDExMjguOTEgMjU3LjE1NSA5ODUuNTk2IDI4Mi4wMDggOTY4Ljc3MmMyNC44NTMtMTYuODIzIDM3NS44NzMtMTU1LjU3OCAxMTIuMjU0LTQ1MS44NTZaIiBmaWxsPSJyZ2JhKDIwNSwgMjEsIDUyLCAwLjIpIj48L3BhdGg+PHBhdGggZD0iTTYwLjk3NSA5NzEuMzEyYy0xMS4zOSAwLTIzLjE0OC0uNjE0LTM1LjI4NS0xLjg0Ni01Mi40NTItNS4zMjEtOTcuNzItMjAuNTgtMTIyLjA0LTI4Ljc3Ny03LjA0LTIuMzc0LTEyLjEyOS00LjA4OS0xNC43MDQtNC42MjUtMTguOTkzLTMuOTUtOTcuMDE4LTI3LjM5LTE4MC4zNTYtNjcuOTQxLTUwLjI5LTI0LjQ3Mi05NS4yMS01MS40MzgtMTMzLjUxLTgwLjE1LTQ3LjAwMS0zNS4yMzMtODQuMTA4LTczLjIxNC0xMTAuMjkzLTExMi44ODUtNTIuMjA5LTc5LjEwMi02OS4zOS0xNjQuNDgtNDkuNjg3LTI0Ni45MDEgMTYuNzQ0LTcwLjAzNyA2MC4yMTctMTM0LjYzIDExOS4yNzMtMTc3LjIxN0MtMzU1IDE3MS4xOTctMzMuNDc3IDgyLjA1MyAxNjEuMjExIDIwMy4yNjhjNTYgMzQuMjQgMTU1LjgzNSAxODIuMTU2IDEzMi44MTggNDk2Ljg4OS04LjQ0MyAxMTUuNDUyLTQ0LjYgMTk1LjE2NS0xMDcuNDY2IDIzNi45MjQtMzQuMjcgMjIuNzY1LTc2LjQwOCAzNC4yMy0xMjUuNTg4IDM0LjIzWk0tNzcuNzkgMTQ0Ljg0NmMtMTU2LjM0MiAwLTMxNS44MTkgNTUuNDI1LTM4Ny4yNTIgMTA2LjkzNi0xMTcuMjkgODQuNTgtMTc4Ljc3IDI1Ni45NTItNjkuMzM1IDQyMi43NTUgNTkuNjYzIDkwLjM5NyAxNjUuMjk2IDE1NC42NzggMjQzLjQwNCAxOTIuNjg2IDkwLjE0NSA0My44NjQgMTY4LjQ0MiA2NS40MzMgMTgwLjEyMyA2Ny44NjIgMi42MzQuNTQ4IDcuNzQ2IDIuMjcgMTQuODIgNC42NTYgNDUuNDE0IDE1LjMxIDE4My42MSA2MS44OSAyODIuMDQtMy40OTMgNjIuNTk1LTQxLjU4IDk4LjYwMi0xMjEuMDM3IDEwNy4wMjEtMjM2LjE2NGwuNS4wMzYtLjUtLjAzNmMyMi45ODItMzE0LjI1Mi03Ni41Mi00NjEuODMzLTEzMi4zNDUtNDk1Ljk2NEM5Mi4wNSAxNjEuMzg0IDcuNTg4IDE0NC44NDUtNzcuNzkgMTQ0Ljg0NVoiIGZpbGw9IiNDRDE0MzIiPjwvcGF0aD48L3N2Zz4=');
                    background-repeat: no-repeat;
                    content: "";
                    display: block;
                    width: 570px;
                    height: 570px;
                    background-position: left bottom;
                }

                svg {
                    display: none;
                    width: calculateRem(570px);
                    height: auto;
                }
            }

            .btn-close {
                width: calculateRem(62px);
                height: calculateRem(62px);
                border-radius: 50%;
                background-color: $white;
                position: absolute;
                top: calculateRem(58px);
                right: calculateRem(59px);
                opacity: 1;
                &:focus {
                    box-shadow: $focus-box-shadow;
                }

                background-image:  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIGlkPSJFYmVuZV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij48cGF0aCBkPSJNLjI5My4yOTNDLjY4MzUtLjA5NzQsMS4zMTY1LS4wOTc0LDEuNzA3LjI5M2w2LjI5Myw2LjI5M0wxNC4yOTMuMjkzYy4zODM3LS4zOTcyLDEuMDE2OC0uNDA4MiwxLjQxNC0uMDI0Ni4zOTcyLjM4MzcuNDA4MiwxLjAxNjguMDI0NiwxLjQxNC0uMDA4LjAwODMtLjAxNjIuMDE2NS0uMDI0Ni4wMjQ2bC02LjI5Myw2LjI5Myw2LjI5Myw2LjI5M2MuMzgzNy4zOTcyLjM3MjcsMS4wMzAzLS4wMjQ1LDEuNDE0LS4zODc1LjM3NDMtMS4wMDE5LjM3NDMtMS4zODk1LDBsLTYuMjkzLTYuMjkzTDEuNzA3LDE1LjcwN2MtLjM5NzIuMzgzNy0xLjAzMDMuMzcyNy0xLjQxNC0uMDI0Ni0uMzc0My0uMzg3NS0uMzc0My0xLjAwMTksMC0xLjM4OTRsNi4yOTMtNi4yOTNMLjI5MywxLjcwN0MtLjA5NzQsMS4zMTY1LS4wOTc0LjY4MzUuMjkzLjI5M1oiIGZpbGw9IiM4YzA1NTAiLz48L3N2Zz4=');
                background-repeat: no-repeat;
                background-position: center center;
            }

            .container {
                top: 50%;
                position: relative;
                transform: translateY(-50%);
                padding-top: calculateRem(80px);
                padding-bottom: calculateRem(80px);
                
                .module--text + form {
                    //margin-top: calculateRem(80px);
                }     
                .search-modal-form {
                    position: relative;
                    width: 100%;
                    margin-bottom: calculateRem(65px);
                    //border-bottom: 2px solid $white !important;
                    padding-left: calculateRem(20px);
                    padding-right: calculateRem(20px);

                    .d-flex.w-100 {
                        position: relative;
                    }
                    label {
                        margin-top: calculateRem(10px);
                        //@include fontSize(18px);
                        @include font-family-meta-normal();
                        @include fontSize(20px);
                        @include lineHeight(32px); 
                    }
                    .d-flex.w-100 {
                        margin-top: calculateRem(70px);
                    }
                }     
            }
        }
    }
}

body.modal-open .scroll-to-top.is--visible {
    z-index: -1;
}