// -----------------------------------------------------------------------------
// This file contains all styles related to the arrow component.
// -----------------------------------------------------------------------------

span.arrow { 
    width: 59px;
    height: 59px;
    display: inline-block;
    position: relative;
    text-align: center;
    vertical-align: middle;

    > svg {
        width: 24px;
        height: 100%;
        position: relative;
        overflow: visible;
    } 

    &.large {
        width: 60px;
        height: 60px;
        > svg {
            width: 26px;
        }        
    }    

    &.medium {
        width: 53px;
        height: 53px;
        > svg {
            width: 23px;
        }        
    }

    &.small {
        width: 41px;
        height: 41px;
        > svg {
            width: 17px;
        }          
    }            

    span.ellipse {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;    
        top: 0;
        svg {
            width: 100%;
            height: 100%;
            overflow: visible;
        }
    }  
}

button:hover > .arrow.right,
a:hover > .arrow.right,
a:hover > span > .arrow.right,
.arrow.right:hover {
    > svg {
      position: relative;
      animation: bounce-hor 1.2s linear infinite;
    }
}

button:hover > .arrow.down,
a:hover > .arrow.down,
a:hover > span > .arrow.down,
.arrow.down:hover {
    > svg {
        position: relative;
        animation: bounce-ver 1.2s linear infinite;
    }
} 
