// -----------------------------------------------------------------------------
// This file contains all styles related to the breadcrumb of the site/application.
// -----------------------------------------------------------------------------

.breadcrumb {
    margin: 0 0;
    padding: 14px 0 12px 0;
    a, li {
        //@include fontSize(14px);
        @include fontSize(16px);
        @include lineHeight(24px);
        color: $primary;
        text-decoration: none;
    }
    li.active {
        color: $custom-primary;
    }
    li:first-child {
        padding-left: 0 !important;
        position: relative;

    }
}

body.pid1,
body.home {
    .breadcrumb {
        display: none;
    }
}