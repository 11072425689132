.powermail-errors-list.filled {
  list-style: none;
  //color: $custom-secondary;
  color: $white;
  margin: 0 0 0 12px;
  padding: 0 0;

  li {
    @include fontSize(14px);
    @include lineHeight(32px); 
    position: relative;
    padding-left: 25px;
    &:before {
      display: block;
      content: '';
      width: 18px;
      height: 18px;
      //background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIGlkPSJFYmVuZV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxOCAxNS43NSI+PHBhdGggZD0ibTkuMDAwMSwxMS41MzEyYy0uNDY1OSwwLS44NDM3LjM3NzgtLjg0MzcuODQzOHMuMzc3OC44NDM4Ljg0MzcuODQzOC44NDM3LS4zNzc4Ljg0MzctLjg0MzgtLjM3NzgtLjg0MzgtLjg0MzctLjg0MzhabTAtMS40MDYyYy4zMTA5LDAsLjU2MjUtLjI1MTYuNTYyNS0uNTYyNXYtNS4wNjI1YzAtLjMxMDktLjI1MTYtLjU2MjUtLjU2MjUtLjU2MjVzLS41NjI1LjI1MTYtLjU2MjUuNTYyNXY1LjA2MjVjMCwuMzEwOS4yNTE2LjU2MjUuNTYyNS41NjI1Wm04LjczMDcsMi43MTkxTDEwLjcwMzQuOTYyNGMtLjM1NDMtLjYwMTUtLjk4OTItLjk2MTMtMS42OTk1LS45NjI0aC0uMDAzM2MtLjcxMDIsMC0xLjM0NzQuMzU5My0xLjcwNDQuOTYxOEwuMjY4OCwxMi44NDQ3Yy0uMzUzMi41OTY1LS4zNTg3LDEuMzEyOC0uMDE0OCwxLjkxNi4zNTM3LjYxOTYuOTk1OC45ODkzLDEuNzE4MS45ODkzaDE0LjA1ODZjLjcyMTIsMCwxLjM2MjItLjM3MDIsMS43MTU5LS45OTA0LjM0MzMtLjYwMzIuMzM3My0xLjMxODktLjAxNTktMS45MTU1Wm0tLjk2MTgsMS4zNTg1Yy0uMTUwNS4yNjQyLS40MjY4LjQyMjQtLjczODIuNDIyNEgxLjk3MjJjLS4zMTMxLDAtLjU4OTktLjE1NzYtLjc0MS0uNDIxOS0uMTQzNC0uMjUxNi0uMTQxMS0uNTM3OC4wMDU1LS43ODZMOC4yNjQxLDEuNTM0OGMuMTUyMi0uMjU2NS40MjczLS40MDk4LjczNzEtLjQwOThoLjAwMTFjLjMwODIuMDAwNi41ODE3LjE1MzIuNzMyNy40MDkzbDcuMDI4LDExLjg4Mjh2LjAwMDVjLjE0NjYuMjQ3Ny4xNDk0LjUzMzkuMDA2Ljc4NVoiIGZpbGw9IiNlODg5OWIiLz48L3N2Zz4=');
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxOCAxNS43NSI+PHBhdGggZD0iTTkgMTEuNTMxYS44NDQuODQ0IDAgMTAwIDEuNjg4Ljg0NC44NDQgMCAwMDAtMS42ODh6bTAtMS40MDZjLjMxMSAwIC41NjMtLjI1Mi41NjMtLjU2M1Y0LjVhLjU2Mi41NjIgMCAxMC0xLjEyNSAwdjUuMDYzYzAgLjMxLjI1MS41NjIuNTYyLjU2MnptOC43MyAyLjcyTDEwLjcwNC45NjFBMS45NTMgMS45NTMgMCAwMDkuMDAzIDBoLS4wMDJjLS43MSAwLTEuMzQ4LjM2LTEuNzA1Ljk2MkwuMjcgMTIuODQ1YTEuODg2IDEuODg2IDAgMDAtLjAxNSAxLjkxNmMuMzU0LjYyLjk5Ni45ODkgMS43MTguOTg5aDE0LjA1OWExLjk1IDEuOTUgMCAwMDEuNzE2LS45OSAxLjg4NyAxLjg4NyAwIDAwLS4wMTYtMS45MTZ6bS0uOTYxIDEuMzU4YS44MzguODM4IDAgMDEtLjczOC40MjJIMS45NzJhLjg0Ljg0IDAgMDEtLjc0LS40MjIuNzY0Ljc2NCAwIDAxLjAwNS0uNzg2TDguMjY0IDEuNTM1YS44NDUuODQ1IDAgMDEuNzM3LS40MWguMDAxYS44NC44NCAwIDAxLjczMy40MWw3LjAyOCAxMS44ODJjLjE0Ny4yNDguMTUuNTM1LjAwNi43ODZ6IiBmaWxsPSIjZmZmZmZmIi8+PC9zdmc+');
      background-repeat: no-repeat;
      position: absolute;
      left: 0;
      top: 8px;
    }
  }
}
.checkbox.powermail_field_error .form-check-input {
  //border-color: $custom-secondary;
  border-color: $white;
}
.checkbox.powermail_field_error .form-check-label,
.checkbox.powermail_field_error .form-check-label a {
  //color: $custom-secondary;
  color: $white;
}

.powermail_fieldwrap_type_check .powermail-errors-list.filled {
  margin-left: 36px;
}

.form-control + .powermail-errors-list.filled {
  margin-bottom: calculateRem(35px);
}

.selectpicker.powermail_field_error + .dropdown-toggle {
  //color: $custom-secondary !important;
  color: $white !important;
}

.selectpicker.powermail_field_error + .dropdown-toggle {
  //border-color: $custom-secondary !important;
  border-color: $white !important;
}