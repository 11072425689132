// -----------------------------------------------------------------------------
// This file contains all styles related to the caption component.
// -----------------------------------------------------------------------------

.module-button {

    position: relative;

    .btn.btn-link {
        padding: 0 0;
        @include fontSize(20px);
        @include lineHeight(22px);  
        //@include font-family-open-sans-medium();
        @include font-family-meta-medium();
        color: $btn-primary;
        text-decoration: none; 
        display: inline-block;
        background-color: transparent !important;
        white-space: nowrap;
        border: 0;
        padding: 0 0 0 0 !important;
        text-align: left;
        margin-top: calculateRem(15px);

        @include media-breakpoint-down(sm) {
            @include fontSize(18px);
            //@include lineHeight(22px); 
        }
        
        &.btn-default {
            span + span.arrow { 
                margin-left: -15px;
            }
            span.ellipse svg {
                path {
                    //fill: $btn-secondary;
                    fill: $custom-primary;
                } 
            } 
            span.arrow > svg {
                path {
                    //fill: $btn-secondary;
                    fill: $custom-primary;
                } 
            }              
        }
        span + span.arrow,
        span.arrow + span { 
            margin-left: 22px;
        } 

        &.btn-secondary {
            color: $btn-secondary;
            span.ellipse svg {
                path {
                    //stroke: $btn-secondary;
                    fill: $btn-secondary;
                } 
            } 
            span.arrow > svg {
                path {
                    //stroke: $btn-secondary;
                    fill: $btn-secondary;
                } 
            }   
        }  

        &.btn-white {
            color: $white;
            span.ellipse svg {
                path {
                    //stroke: $white;
                    //fill: $btn-secondary;
                    fill: $white;
                } 
            } 
            span.arrow > svg {
                path {
                    //stroke: $white;
                    fill: $white;
                } 
            }   
        }    
        
        margin-right: calculateRem(25px);
        &:last-child {
            margin-right: 0;
        }
    }
    &.text-white {
        svg {
            path {
                fill: $white;
            } 
        }    
    }    
}