// -----------------------------------------------------------------------------
// This file contains all styles related to the Color component.
// -----------------------------------------------------------------------------

.text-white {
    .title,
    h1, h2, h3, h4, h5, li {
        color: $white;
        a {
            color: $white;
        }
    } 
    .module-caption {
        * {
            color: $white;
        }
        p {
            &.location, &.email, &.phone {
                a {
                    color:  $white !important;
                }
                span svg path {
                    fill: $white !important;
                }                 
            }            
        }
    }
    .module-caption ul li:before {
        background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIGlkPSJFYmVuZV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA5IDE3Ij48cGF0aCBkPSJtOSw4LjQ4YzAtMS45NTk5LTEuNjItMy40Nzk4LTEuNjItMy40Nzk4TDMuNDIuNjAwM0MyLjctLjIzOTYsMS42Mi0uMTU5Ni45MzYuNjAwM2wtLjkzNiwxLjA0LDQuMTA0LDQuNTU5OHMxLjI5NiwxLjIzOTksMS4yOTYsMi4zMTk5LTEuMjk2LDIuMzE5OS0xLjI5NiwyLjMxOTlMMCwxNS4zNTk3bC45MzYsMS4wNGMuNjg0Ljc1OTksMS43MjguODM5OSwyLjQ4NCwwbDMuOTYtNC4zOTk5czEuNjItMS41MTk5LDEuNjItMy41MTk4WiIgZmlsbD0iI2ZmZiIvPjwvc3ZnPg==');
    }  

    .module--listmenu {
        ul li a,
        &.download ul li a > span:first-child {
            color: $white;
        }
        ul li a svg path {
            fill: $white;
        }
        ul li {
            border-color: $white !important;
        }        
    }
}