// -----------------------------------------------------------------------------
// This file contains all styles related to the newsfilter component.
// -----------------------------------------------------------------------------
.news-filter {
    
    //margin-top: calculateRem(10px);
    //padding: calculateRem(75px) calculateRem(50px); // + 30px select
    padding: calculateRem(75px) 0;

    .module--text + .filter {
        margin-top: calculateRem(50px);
    }
}
.pagetitle.section-content + .section-content {
    margin-top: calculateRem(10px);
}
.section-content.news-filter + .section-content {
    margin-top: calculateRem(150px);
}