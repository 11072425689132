// -----------------------------------------------------------------------------
// This file contains all styles related to the newsfilter component.
// -----------------------------------------------------------------------------


.location-filter {
    width: 100%;
    //overflow-x: hidden;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    & + .location-filter {
        margin-top: calculateRem(44px) !important;
        @include media-breakpoint-down(md) {
            margin-top: calculateRem(11px) !important;
        }
    }

    &.form {

        //background: linear-gradient(87.96deg, #004780 19.07%, #5C8BAC 100%);
        background: $primary;
        min-height: calculateRem(600px);

        height: 600px;
        @include media-breakpoint-down(md) {
            height: auto;
        }

        .module--media {
            height: 100%;
            .figure {
                height: 100%;
                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }            
            }
            @include media-breakpoint-down(md) {
                display: none;
            }

            &.is--gradient-r .figure:before {
                background: radial-gradient(50% 50% at 50% 50%, rgba(0, 15, 27, 0.7) 0%, rgba(0, 15, 27, 0.7) 100%);     
            }
        }

        .filter {
            position: absolute;
            bottom: calculateRem(30px); // + 30px form-control
            width: 100%;
            height: auto;
            z-index: 10;
            @include media-breakpoint-down(md) {
                position: relative;
                bottom: auto;
                padding-top: calculateRem(45px);
                padding-bottom: calculateRem(54px);
            }

            form {
                margin-top: calculateRem(60px);
            }
        }
    }

    &.map {

        @include media-breakpoint-up(sm) {
            .container {
                width: 100% !important;
                padding: 0 0 !important;
            }
        }
    }

    .module--map {
        height: calculateRem(637px);
        width: 100%;
        position: relative;
        height: 100%;
        overflow: hidden;
    }

    .module--result {
        height: calculateRem(637px);
        max-width: calculateRem(605px);

        @include media-breakpoint-down(md) {
            padding-right: 0;
            padding-left: 0;
            max-width: 100%;
        }

        .result-inner {

            width: 100%;
            height: 100%;
            overflow-y: auto;
            overflow-x: hidden;
        
            padding-right: calc(var(--bs-gutter-x)*1);
            padding-left: calc(var(--bs-gutter-x)*1);
            padding-top: calculateRem(20px);
            padding-bottom: calculateRem(25px);

            .item {
                width: 100%;
                height: auto;
                padding: calculateRem(22px) calculateRem(38px) calculateRem(0px) calculateRem(38px);
                position: relative;
                @include media-breakpoint-down(md) {
                    padding: calculateRem(30px) 0 calculateRem(8px) 0;
                }

                @include media-breakpoint-down(md) {
                    background-color: transparent !important; 
                    &:before {
                        content:'';
                        border-top: 1px solid $white;
                        width: 100vw;
                        position: absolute;
                        left: 50%;
                        transform: translateX(-50%);
                        top: 0;
                        display: block;
                    }
                    &:first-child:before {
                        display: none;
                    }
                }

                .module-title {
                    margin-bottom: calculateRem(15px);
                    h2 {
                        @include fontSize(20px);
                        @include lineHeight(28px);  
                        @include font-family-meta-bold();
                        margin-bottom: 0;
                        color:$body-color;
                    }                                            
                }    
                .module-caption {
                    margin-top: 0;
                    margin-bottom: calculateRem(22px);
                    @include media-breakpoint-down(md) {
                        margin-bottom: calculateRem(25px);
                    }                    
                    p {
                        margin-bottom: 0.25rem;
                        &.location {
                            margin-top: -5px;
                        }
                    }
                }
                .module--media {
                    margin-bottom: calculateRem(22px);
                    @include media-breakpoint-down(md) {
                        margin-bottom: calculateRem(25px);
                    }
                }

                &:hover {
                    background-color: $white;                     
                }
            }           
        }
    }
}