// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------
.site-footer {
    position: relative;
    background-color: $grey-dark;
    overflow: hidden;
    .footer--bg {
        background-color: $body-bg;
        position: absolute;
        left: 0;
        right: 0;
        z-index: 1;
        overflow: hidden;
        img, svg {
            width: 100%;
            min-width: 100%;
            height: auto;
            position: relative;
        }   
        @include media-breakpoint-down(sm) {
            display: none;
        }
    }
    h5 {
        @include font-family-meta-bold();
        margin-bottom: 1.5rem;
    }
    h5 {
        
        text-decoration: none;
        color: $white;
        @include fontSize(16px);
        @include lineHeight(24px);  
    } 
    p, a {
        
        text-decoration: none;
        color: $white;
        @include fontSize(16px);
        @include lineHeight(24px);  
    } 

    a:hover, a:active, a:focus {
        //color: $custom-secondary;
        @include fontSize(16px);
        @include lineHeight(24px);          
        color: $white;
        text-decoration: underline;
        @include font-family-meta-bold();        
    }

    .nav li, .nav .nav-link {
        padding: 0 0;
    }
    .footer--top {
        position: relative;
        z-index: 2;
        padding: calculateRem(110px) 0 0 0;
        @include media-breakpoint-down(sm) {
            padding: calculateRem(55px) 0 0 0;
        }
        .footer-logo {
            width: 155px;
            height: auto;
            margin-top: 8px;
            margin-bottom: calculateRem(63px);
        }
        ul {
            margin-bottom: calculateRem(50px);
        }
        ul.footer-logos {
            
            margin-bottom: calculateRem(28px);
            img {
                width: auto;
                height: 74px;   
            }
            li {
                margin-right: 22px;
                margin-bottom: 22px;
                float: left;
                &:last-child {
                    margin-right: 0;
                }
                @include media-breakpoint-up(md) {
                    &:nth-child(odd) {
                        clear: both;
                    }
                }
            }
        }
        p {
            a {

            }
            &.location, &.email, &.phone {
                position: relative;
                padding-left: calculateRem(30px);
                span {
                    display: inline-block;
                    position: absolute;
                    top: 0px;
                    width: 16px;
                    height: 25px;
                    margin-left: -30px; 
                    svg {
                        width: 100%;
                        height: 100%;
                        path {
                            fill: $white;
                        }
                    }                
                }
                a {
                    text-decoration: none;
                    @include font-family-meta-normal();
                    //color: $white;
                }
            }
        }
        ul  {
            li.home span {
                width: 13px;
                height: 13px;
                margin-left: 8px;
                display: inline-block;
                svg {
                    width: 100%;
                    height: 100%;
                    path {
                        fill: $white;
                    }
                }
            } 
            li + li.home {
                margin-top: calculateRem(24px);
            }
        }                

    }
    .footer--bottom {
        position: relative;
        z-index: 2;
        h5, p, a {
            @include lineHeight(19px);  
        } 
        padding: 28px 0 28px 0;
        ul {
            li + li {
                margin-left: 35px;
                @include media-breakpoint-down(sm) {
                    margin-left: 22px;
                }
            }
        }
    }    
    // Newsletterformular
    .module-button {
        margin-top: 0;
        .btn.btn-link {
            @include fontSize(18px);
            @include lineHeight(20px);  
        }        
    }
    .module-button .btn.btn-link.btn-default span + span.arrow {
        margin-left: -10px;
    }
    span.arrow { 
        width: 45px;
        height: 45px;
    
        > svg {
            width: 22px;
            height: 100%;
            position: relative;
            overflow: visible;
        } 
    }
}