// -----------------------------------------------------------------------------
// This file contains all styles related to the hero component.
// -----------------------------------------------------------------------------

.module--hero {
    margin-top: 0 !important;
    .module--media {
        height: 600px;
        @include media-breakpoint-down(xl) {
            height: 400px;
        }
        @include media-breakpoint-down(lg) {
            height: 300px;
        }
        @include media-breakpoint-down(md) {
            height: 200px;
        }        
        .figure {
            height: 100%;
            width: 100%;
            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }         
        }
    }
    &.section-content + .section-content {
        margin-top: marincalculateRem(85px);
    }
}