// -----------------------------------------------------------------------------
// When having several themes, this file contains everything related to the
// default one.
// -----------------------------------------------------------------------------



//body.career {
//body.pid2531,
//body.pid2532,
body.career {    

    .breadcrumb {
        display: none;
    }

    // Slider
    .module--slider.hero .swiper .swiper-slide .module--media .figure:after {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        //z-index: 1;
        //background: linear-gradient(42deg, rgba(0, 71, 128, 0.58) 0%, rgba(141, 4, 79, 0.51) 100%), lightgray 285.156px -19.154px / 89.499% 118.685% no-repeat;
        //background: linear-gradient(86.73deg, rgba(205, 20, 50, 0.3) 18.48%, rgba(140, 5, 80, 0.3) 82.96%);
        background: linear-gradient(100.85deg, rgba(205, 20, 50, 0.49) 23.4%, rgba(140, 5, 80, 0.49) 57.81%, rgba(20, 45, 60, 0.49) 85.89%);
        background-blend-mode: multiply;
        //mix-blend-mode: multiply;
        opacity: 0.7;
    }

    // Carousel 
    .module--slider.carousel .swiper-slide .module--text::before {
       // background: linear-gradient(223deg, #0E4972 0%, #8D044F 100%);
    }
    
    .txt-img.bg--blue-gradient {
       // background: linear-gradient(225deg, #E8899B 0%, #0E4972 100%);
    }
    
    .section-content.bg--grey-light {
       // background: linear-gradient(225deg, #E8899B 0%, #0E4972 100%);
       background: $bg-linear-gradient-2;
    }
    .section-content.bg--grey-light .module--icons .module-icon::before {
        background-color: #FFF;
        opacity: 0.3;
    }

    .section-content.bg--blue-gradient,
    .bg--blue-gradient, .bg--blue-gradient-2 {
      //  background: linear-gradient(225deg, #E8899B 0%, #0E4972 100%);    
    }

    .section-content.bg--blue-gradient .module-pretitle.bg-title > div span {
        color: #FFF;
    }

    .module--text.bg--blue-gradient {

       
       // background: linear-gradient(223deg, #8D044F 0%, #0E4972 100%);
    }
    

    .module--quote:before { 
      //  background: linear-gradient(223deg, #0E4972 0%, #8D044F 100%);
    }
    
    .section-content.bg-blue-gradient.left,
    .section-content.bg-blue-gradient.right {
        height: 0;
        background: transparent;
    }

    .section-content {
        > .bg--img {
            //background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIGlkPSJFYmVuZV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgNjM1IDEyNjEiPjxkZWZzPjxsaW5lYXJHcmFkaWVudCBpZD0iVW5iZW5hbm50ZXJfVmVybGF1Zl8yIiB4MT0iNzM0LjU0OSIgeTE9Ijc1LjQ5NDMiIHgyPSI3ODguNTQ5MyIgeTI9IjEwMTEuOTk4NCIgZ3JhZGllbnRUcmFuc2Zvcm09InRyYW5zbGF0ZSgwIDEyNTkuODkpIHNjYWxlKDEgLTEpIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSI+PHN0b3Agb2Zmc2V0PSIwIiBzdG9wLWNvbG9yPSIjMDA0ODc1Ii8+PHN0b3Agb2Zmc2V0PSIxIiBzdG9wLWNvbG9yPSIjOGQwNDRmIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHBhdGggZD0ibTE4NS40OCw0NTYuNjMzYzIyNy4wMDUtMjc3Ljc1OSw0MjIuNzM2LTMxNy42Niw0OTIuMjI1LTMwMi44OSwyNTEuOTU3LDUxLjQxNiw0NDUuODg0OSwzNzIuMjIzLDQ3NC42MDUsNTIzLjMxOSwyOC43MiwxNTEuMDk1LTQ0LjAzLDM0Ni42MzgtMjcwLjc2NCwzOTkuNTM4LTIyNi43MzEsNTIuOTEtNTE4Ljk1Ni05Ny4wMTEtNTQ3LjgzLTExNC44MzgtMjguODc0LTE3LjgyNy00MzEuOTkxOC0xNTcuOTMwMS0xNDguMjM2LTUwNS4xMjlaIiBmaWxsPSIjZjVmNWY1Ii8+PHBhdGggZD0ibTM1NC40NzQ3LDkyMy43ODk4Yy0xMDIuNDc3NS0zMjUuOTY3LTMxLjkxNDgtNTAyLjY3NSwxNi4xNzYyLTU1MC4yODMsMTcyLjY2ODItMTczLjczNTEsNTI5LjQ5MzItMTYwLjQxODMsNjY0LjgxNzItMTA0LjI5MjYsMTM1LjMyNCw1Ni4xMjU4LDI1Mi45ODYsMjE2LjI5MjYsMTc2LjU2OCw0MjQuNDgxNi03Ni40MTksMjA4LjE4OS0zNDguNTcsMzYyLjQ4OC0zNzcuODYxLDM3Ni4xNTgtMjkuMjksMTMuNjctMzUxLjYwNCwyNjEuMzk0LTQ3OS43MDA0LTE0Ni4wNjRaIiBmaWxsPSJub25lIiBzdHJva2U9InVybCgjVW5iZW5hbm50ZXJfVmVybGF1Zl8yKSIvPjwvc3ZnPg==');
            //background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIGlkPSJFYmVuZV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgMTA5NC4wMDMxIDEwMDcuNTQ5NCI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJVbmJlbmFubnRlcl9WZXJsYXVmXzIiIHgxPSI1OTEuODgyOSIgeTE9Ii03Ny4wNzg1IiB4Mj0iNjQ1Ljg4MzMiIHkyPSI4NTkuNDI3IiBncmFkaWVudFRyYW5zZm9ybT0idHJhbnNsYXRlKDAgOTU3LjQ4NDcpIHNjYWxlKDEgLTEpIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSI+PHN0b3Agb2Zmc2V0PSIwIiBzdG9wLWNvbG9yPSIjMDA0ODc1Ii8+PHN0b3Agb2Zmc2V0PSIxIiBzdG9wLWNvbG9yPSIjOGQwNDRmIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHBhdGggZD0ibTEwMC42ODY5LDMwNS41Mzg1QzMyNy42OTE5LDI3Ljc3OTUsNTIzLjQyMjktMTIuMTIxNSw1OTIuOTEyLDIuNjQ4NWMyNTEuOTU3LDUxLjQxNiw0NDUuODg0OSwzNzIuMjIzLDQ3NC42MDUsNTIzLjMxOSwyOC43MiwxNTEuMDk1LTQ0LjAzLDM0Ni42MzgtMjcwLjc2NCwzOTkuNTM4LTIyNi43MzEsNTIuOTEtNTE4Ljk1Ni05Ny4wMTEtNTQ3LjgzLTExNC44MzgtMjguODc0LTE3LjgyNy00MzEuOTkxOC0xNTcuOTMwMS0xNDguMjM2LTUwNS4xMjlaIiBmaWxsPSIjZjVmNWY1Ii8+PHBhdGggZD0ibTIxMS44MTA5LDc3My45NTc1Yy0xMDIuNDc4LTMyNS45NjctMzEuOTE1LTUwMi42NzUsMTYuMTc2LTU1MC4yODNDNDAwLjY1NDksNDkuOTM5NSw3NTcuNDgsNjMuMjU2NSw4OTIuODAzOSwxMTkuMzgxNWMxMzUuMzIzMSw1Ni4xMjYsMjUyLjk4MywyMTYuMjkzLDE3Ni41NjMsNDI0LjQ4Mi03Ni40MSwyMDguMTg5LTM0OC41NjUxLDM2Mi40OTE5LTM3Ny44NTYsMzc2LjE2Mi0yOS4yOSwxMy42Ny0zNTEuNjA0LDI2MS4zOS00NzkuNy0xNDYuMDY4WiIgZmlsbD0ibm9uZSIgc3Ryb2tlPSJ1cmwoI1VuYmVuYW5udGVyX1ZlcmxhdWZfMikiLz48L3N2Zz4=');
        }
    }

    .section-content.txt-img.bg--blue-gradient .module--media .bg--img {
        &:before {
            //background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIGlkPSJFYmVuZV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgNjQxLjM3NjEgNTYwLjMzNzIiPjxkZWZzPjxsaW5lYXJHcmFkaWVudCBpZD0iVW5iZW5hbm50ZXJfVmVybGF1ZiIgeDE9IjEzOS4xMDY0IiB5MT0iMTIuMTI2MyIgeDI9IjU1OS4xMDY0IiB5Mj0iNTAyLjYyNjMiIGdyYWRpZW50VHJhbnNmb3JtPSJ0cmFuc2xhdGUoMCA1MzMuNjg2Mykgc2NhbGUoMSAtMSkiIGdyYWRpZW50VW5pdHM9InVzZXJTcGFjZU9uVXNlIj48c3RvcCBvZmZzZXQ9IjAiIHN0b3AtY29sb3I9IiMwMDQ4NzUiLz48c3RvcCBvZmZzZXQ9IjEiIHN0b3AtY29sb3I9IiM4ZDA0NGYiLz48L2xpbmVhckdyYWRpZW50PjwvZGVmcz48cGF0aCBkPSJtNTYuMDQ0LDE3MC4wNjgyQzE4Mi4zOTksMTUuNDYyNCwyOTEuMzQ2LTYuNzQ2OSwzMzAuMDI1LDEuNDc0MmMxNDAuMjQzLDI4LjYxOSwyNDguMTg1LDIwNy4xODYsMjY0LjE3MywyOTEuMjg4LDE1Ljk4OCw4NC4xMDItMjQuNTA5LDE5Mi45NDYtMTUwLjcxMiwyMjIuMzkyLTEyNi4yMDIsMjkuNDQ2LTI4OC44Ni01NC4wMDEtMzA0LjkzMS02My45MjMtMTYuMDcyLTkuOTIzLTI0MC40NTQyLTg3LjkwNy04Mi41MTEtMjgxLjE2M1oiIGZpbGw9InJnYmEoMjU1LDI1NSwyNTUsLjMpIi8+PHBhdGggZD0ibTE1MC4xMDksNDMwLjA5NTJjLTU3LjA0MS0xODEuNDM5LTE3Ljc2NC0yNzkuNzk3LDkuMDA0LTMwNi4yOTcsOTYuMTEtOTYuNzA0LDI5NC43MjUtODkuMjkxLDM3MC4wNDktNTguMDUxLDc1LjMyMywzMS4yNDEsMTQwLjgxNiwxMjAuMzkyLDk4LjI4LDIzNi4yNzQtNDIuNTM1OSwxMTUuODgxLTE5NC4wMiwyMDEuNzY2LTIxMC4zMjMsMjA5LjM3NS0xNi4zMDQsNy42MDktMTk1LjcwOSwxNDUuNDk4LTI2Ny4wMS04MS4zMDFaIiBmaWxsPSJub25lIiBzdHJva2U9InVybCgjVW5iZW5hbm50ZXJfVmVybGF1ZikiLz48L3N2Zz4=');
            //background-image:  url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2NDEuMzc2IDU2MC4zMzciPjxkZWZzPjxsaW5lYXJHcmFkaWVudCBpZD0iYSIgeDE9IjEzOS4xMDYiIHkxPSIxMi4xMjYiIHgyPSI1NTkuMTA2IiB5Mj0iNTAyLjYyNiIgZ3JhZGllbnRUcmFuc2Zvcm09Im1hdHJpeCgxIDAgMCAtMSAwIDUzMy42ODYpIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSI+PHN0b3Agb2Zmc2V0PSIwIiBzdG9wLWNvbG9yPSIjMDA0ODc1Ii8+PHN0b3Agb2Zmc2V0PSIxIiBzdG9wLWNvbG9yPSIjOGQwNDRmIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHBhdGggZD0iTTU2LjA0NCAxNzAuMDY4QzE4Mi4zOTkgMTUuNDYyIDI5MS4zNDYtNi43NDcgMzMwLjAyNSAxLjQ3NCA0NzAuMjY4IDMwLjA5NCA1NzguMjEgMjA4LjY2IDU5NC4xOTggMjkyLjc2MnMtMjQuNTA5IDE5Mi45NDYtMTUwLjcxMiAyMjIuMzkyYy0xMjYuMjAyIDI5LjQ0Ni0yODguODYtNTQtMzA0LjkzMS02My45MjMtMTYuMDcyLTkuOTIzLTI0MC40NTQtODcuOTA3LTgyLjUxMS0yODEuMTYzeiIgZmlsbD0icmdiYSgyNTUsMjU1LDI1NSwuMykiLz48cGF0aCBkPSJNMTUwLjEwOSA0MzAuMDk1Yy01Ny4wNDEtMTgxLjQzOS0xNy43NjQtMjc5Ljc5NyA5LjAwNC0zMDYuMjk3IDk2LjExLTk2LjcwNCAyOTQuNzI1LTg5LjI5IDM3MC4wNDktNTguMDUgNzUuMzIzIDMxLjI0IDE0MC44MTYgMTIwLjM5MSA5OC4yOCAyMzYuMjczLTQyLjUzNiAxMTUuODgxLTE5NC4wMiAyMDEuNzY2LTIxMC4zMjMgMjA5LjM3NS0xNi4zMDQgNy42MS0xOTUuNzA5IDE0NS40OTgtMjY3LjAxLTgxLjN6IiBmaWxsPSJub25lIiBzdHJva2U9IiNDRDE0MzIiLz48L3N2Zz4=');
            background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2NDEuMzc2IDU2MC4zMzciPjxwYXRoIG9wYWNpdHk9IjAuMDUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgZD0iTTU2LjA0NCAxNzAuMDY4QzE4Mi4zOTkgMTUuNDYyIDI5MS4zNDYtNi43NDcgMzMwLjAyNSAxLjQ3NCA0NzAuMjY4IDMwLjA5NCA1NzguMjEgMjA4LjY2IDU5NC4xOTggMjkyLjc2MnMtMjQuNTA5IDE5Mi45NDYtMTUwLjcxMiAyMjIuMzkyYy0xMjYuMjAyIDI5LjQ0Ni0yODguODYtNTQtMzA0LjkzMS02My45MjMtMTYuMDcyLTkuOTIzLTI0MC40NTQtODcuOTA3LTgyLjUxMS0yODEuMTYzeiIgZmlsbD0iI0ZGRkZGRiIvPjxwYXRoIG9wYWNpdHk9IjAuMzUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgZD0iTTE1MC4xMDkgNDMwLjA5NWMtNTcuMDQxLTE4MS40MzktMTcuNzY0LTI3OS43OTcgOS4wMDQtMzA2LjI5NyA5Ni4xMS05Ni43MDQgMjk0LjcyNS04OS4yOSAzNzAuMDQ5LTU4LjA1IDc1LjMyMyAzMS4yNCAxNDAuODE2IDEyMC4zOTEgOTguMjggMjM2LjI3My00Mi41MzYgMTE1Ljg4MS0xOTQuMDIgMjAxLjc2Ni0yMTAuMzIzIDIwOS4zNzUtMTYuMzA0IDcuNjEtMTk1LjcwOSAxNDUuNDk4LTI2Ny4wMS04MS4zeiIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjRkZGRkZGIi8+PC9zdmc+');
            background-size: 100% 100%;
            background-repeat: no-repeat;
        }
    }

    .module--media {
        .bg--img {
            &:before {
              //background-image: url(' data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIGlkPSJFYmVuZV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgNjQxLjM3NTkgNTYwLjMzNzIiPjxkZWZzPjxsaW5lYXJHcmFkaWVudCBpZD0iVW5iZW5hbm50ZXJfVmVybGF1ZiIgeDE9IjEzNC45NTg3IiB5MT0iNDAuNjE1IiB4Mj0iNTYyLjQ1ODciIHkyPSI0NzcuMTE0OSIgZ3JhZGllbnRUcmFuc2Zvcm09InRyYW5zbGF0ZSgwIDUzMy42ODYzKSBzY2FsZSgxIC0xKSIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiPjxzdG9wIG9mZnNldD0iMCIgc3RvcC1jb2xvcj0iIzAwNDg3NSIvPjxzdG9wIG9mZnNldD0iMSIgc3RvcC1jb2xvcj0iIzhkMDQ0ZiIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxwYXRoIGQ9Im01Ni4wNDM5LDE3MC4wNjgyQzE4Mi4zOTg5LDE1LjQ2MjQsMjkxLjM0NTktNi43NDY5LDMzMC4wMjQ5LDEuNDc0MmMxNDAuMjQzLDI4LjYxOSwyNDguMTg1LDIwNy4xODYsMjY0LjE3MywyOTEuMjg4LDE1Ljk4OCw4NC4xMDItMjQuNTA5LDE5Mi45NDYtMTUwLjcxMiwyMjIuMzkyLTEyNi4yMDIsMjkuNDQ2LTI4OC44Ni01NC4wMDEtMzA0LjkzMS02My45MjMtMTYuMDcyLTkuOTIzLTI0MC40NTM5LTg3LjkwNy04Mi41MTEtMjgxLjE2M1oiIGZpbGw9IiNmNWY1ZjUiLz48cGF0aCBkPSJtMTUwLjEwODksNDMwLjA5NTJjLTU3LjA0LTE4MS40MzktMTcuNzY0LTI3OS43OTcsOS4wMDQtMzA2LjI5Nyw5Ni4xMS05Ni43MDQsMjk0LjcyNS04OS4yOTEsMzcwLjA0OS01OC4wNTEsNzUuMzIzLDMxLjI0MSwxNDAuODE2LDEyMC4zOTIsOTguMjgsMjM2LjI3NC00Mi41MzU5LDExNS44ODEtMTk0LjAyLDIwMS43NjYtMjEwLjMyMywyMDkuMzc1LTE2LjMwNCw3LjYwOS0xOTUuNzA5LDE0NS40OTgtMjY3LjAxLTgxLjMwMVoiIGZpbGw9Im5vbmUiIHN0cm9rZT0idXJsKCNVbmJlbmFubnRlcl9WZXJsYXVmKSIvPjwvc3ZnPg==');
              //background-image:  url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2NDEuMzc2IDU2MC4zMzciPjxkZWZzPjxsaW5lYXJHcmFkaWVudCBpZD0iYSIgeDE9IjEzOS4xMDYiIHkxPSIxMi4xMjYiIHgyPSI1NTkuMTA2IiB5Mj0iNTAyLjYyNiIgZ3JhZGllbnRUcmFuc2Zvcm09Im1hdHJpeCgxIDAgMCAtMSAwIDUzMy42ODYpIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSI+PHN0b3Agb2Zmc2V0PSIwIiBzdG9wLWNvbG9yPSIjMDA0ODc1Ii8+PHN0b3Agb2Zmc2V0PSIxIiBzdG9wLWNvbG9yPSIjOGQwNDRmIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHBhdGggZD0iTTU2LjA0NCAxNzAuMDY4QzE4Mi4zOTkgMTUuNDYyIDI5MS4zNDYtNi43NDcgMzMwLjAyNSAxLjQ3NCA0NzAuMjY4IDMwLjA5NCA1NzguMjEgMjA4LjY2IDU5NC4xOTggMjkyLjc2MnMtMjQuNTA5IDE5Mi45NDYtMTUwLjcxMiAyMjIuMzkyYy0xMjYuMjAyIDI5LjQ0Ni0yODguODYtNTQtMzA0LjkzMS02My45MjMtMTYuMDcyLTkuOTIzLTI0MC40NTQtODcuOTA3LTgyLjUxMS0yODEuMTYzeiIgZmlsbD0icmdiYSgyNTUsMjU1LDI1NSwuMykiLz48cGF0aCBkPSJNMTUwLjEwOSA0MzAuMDk1Yy01Ny4wNDEtMTgxLjQzOS0xNy43NjQtMjc5Ljc5NyA5LjAwNC0zMDYuMjk3IDk2LjExLTk2LjcwNCAyOTQuNzI1LTg5LjI5IDM3MC4wNDktNTguMDUgNzUuMzIzIDMxLjI0IDE0MC44MTYgMTIwLjM5MSA5OC4yOCAyMzYuMjczLTQyLjUzNiAxMTUuODgxLTE5NC4wMiAyMDEuNzY2LTIxMC4zMjMgMjA5LjM3NS0xNi4zMDQgNy42MS0xOTUuNzA5IDE0NS40OTgtMjY3LjAxLTgxLjN6IiBmaWxsPSJub25lIiBzdHJva2U9IiNDRDE0MzIiLz48L3N2Zz4=');
            } 

            &.layout--2 {
                &:before {
                //   background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIGlkPSJFYmVuZV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgNjQxLjg3NDEgNTYwLjgzNzgiPjxkZWZzPjxsaW5lYXJHcmFkaWVudCBpZD0iVW5iZW5hbm50ZXJfVmVybGF1ZiIgeDE9IjYxLjUzODYiIHkxPSI2Mi42NDQ0IiB4Mj0iNjAwLjAzODYiIHkyPSI0MjguNjQ0NCIgZ3JhZGllbnRUcmFuc2Zvcm09InRyYW5zbGF0ZSgwIDUzNC42ODYzKSBzY2FsZSgxIC0xKSIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiPjxzdG9wIG9mZnNldD0iMCIgc3RvcC1jb2xvcj0iIzhkMDQ0ZiIvPjxzdG9wIG9mZnNldD0iMSIgc3RvcC1jb2xvcj0iI2U4ODk5YiIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxwYXRoIGQ9Im01Ni41NDQsMTcwLjU2ODJDMTgyLjg5OSwxNS45NjI0LDI5MS44NDYtNi4yNDY5LDMzMC41MjUsMS45NzQyYzE0MC4yNDMsMjguNjE5LDI0OC4xODUsMjA3LjE4NiwyNjQuMTczLDI5MS4yODgsMTUuOTg4LDg0LjEwMi0yNC41MDksMTkyLjk0Ni0xNTAuNzEyLDIyMi4zOTItMTI2LjIwMiwyOS40NDYtMjg4Ljg2LTU0LjAwMS0zMDQuOTMxLTYzLjkyMy0xNi4wNzItOS45MjMtMjQwLjQ1NDItODcuOTA3LTgyLjUxMS0yODEuMTYzWiIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmIi8+PHBhdGggZD0ibTE1MC42MDcsNDMwLjU5NjJjLTU3LjA0LTE4MS40MzktMTcuNzY0LTI3OS43OTgsOS4wMDQtMzA2LjI5Nyw5Ni4xMS05Ni43MDQsMjk0LjcyNS04OS4yOTIsMzcwLjA0OS01OC4wNTEsNzUuMzIzLDMxLjI0LDE0MC44MTYsMTIwLjM5Miw5OC4yOCwyMzYuMjczLTQyLjUzNiwxMTUuODgyLTE5NC4wMiwyMDEuNzY3LTIxMC4zMjMsMjA5LjM3Ni0xNi4zMDQsNy42MDg5LTE5NS43MDksMTQ1LjQ5NjktMjY3LjAxLTgxLjMwMVoiIGZpbGw9Im5vbmUiIHN0cm9rZT0idXJsKCNVbmJlbmFubnRlcl9WZXJsYXVmKSIvPjwvc3ZnPg==');
                background-image:  url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2NDEuMzc2IDU2MC4zMzciPjxkZWZzPjxsaW5lYXJHcmFkaWVudCBpZD0iYSIgeDE9IjEzOS4xMDYiIHkxPSIxMi4xMjYiIHgyPSI1NTkuMTA2IiB5Mj0iNTAyLjYyNiIgZ3JhZGllbnRUcmFuc2Zvcm09Im1hdHJpeCgxIDAgMCAtMSAwIDUzMy42ODYpIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSI+PHN0b3Agb2Zmc2V0PSIwIiBzdG9wLWNvbG9yPSIjMDA0ODc1Ii8+PHN0b3Agb2Zmc2V0PSIxIiBzdG9wLWNvbG9yPSIjOGQwNDRmIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHBhdGggZD0iTTU2LjA0NCAxNzAuMDY4QzE4Mi4zOTkgMTUuNDYyIDI5MS4zNDYtNi43NDcgMzMwLjAyNSAxLjQ3NCA0NzAuMjY4IDMwLjA5NCA1NzguMjEgMjA4LjY2IDU5NC4xOTggMjkyLjc2MnMtMjQuNTA5IDE5Mi45NDYtMTUwLjcxMiAyMjIuMzkyYy0xMjYuMjAyIDI5LjQ0Ni0yODguODYtNTQtMzA0LjkzMS02My45MjMtMTYuMDcyLTkuOTIzLTI0MC40NTQtODcuOTA3LTgyLjUxMS0yODEuMTYzeiIgZmlsbD0icmdiYSgyNTUsMjU1LDI1NSwuMykiLz48cGF0aCBkPSJNMTUwLjEwOSA0MzAuMDk1Yy01Ny4wNDEtMTgxLjQzOS0xNy43NjQtMjc5Ljc5NyA5LjAwNC0zMDYuMjk3IDk2LjExLTk2LjcwNCAyOTQuNzI1LTg5LjI5IDM3MC4wNDktNTguMDUgNzUuMzIzIDMxLjI0IDE0MC44MTYgMTIwLjM5MSA5OC4yOCAyMzYuMjczLTQyLjUzNiAxMTUuODgxLTE5NC4wMiAyMDEuNzY2LTIxMC4zMjMgMjA5LjM3NS0xNi4zMDQgNy42MS0xOTUuNzA5IDE0NS40OTgtMjY3LjAxLTgxLjN6IiBmaWxsPSJub25lIiBzdHJva2U9IiNDRDE0MzIiLz48L3N2Zz4=');
                }    
            }
        }
    }

    .icon-content {
        .title {
            color :$white;
        }
        color :$white;
    }

}