// -----------------------------------------------------------------------------
// This file contains all styles related to the table component.
// -----------------------------------------------------------------------------

.module--table, table {
    width: 100%;  
    td, th {
        border: 1px solid $border-grey-light;
        //text-align: center;
        vertical-align: middle;
        padding: calculateRem(15px) calculateRem(20px);
        @include media-breakpoint-down(md) {
            padding: 0.5rem 0.5rem;
            //word-break: break-word;
        }
        &:first-child {
            text-align: left;
            @include media-breakpoint-down(md) {
                //word-break: unset;
            }
        }
        p:last-child {
            margin-bottom: 0;
        }
    }
}