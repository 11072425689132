// -----------------------------------------------------------------------------
// This file contains all styles related to the counter component.
// -----------------------------------------------------------------------------
.module--counter {
    padding: calculateRem(70px) 0 calculateRem(40px);

    .row {
        .col {
            .counter-inner {
                text-align: center;
                height: 100%;
                padding-top: calculateRem(20px);
                padding-bottom: calculateRem(50px);
            }
    
            min-width: 25% !important;
    
            @include media-breakpoint-down(lg) {
                min-width: 50% !important;
            }  
            @include media-breakpoint-down(sm) {
                min-width: 100% !important;
            }                    
        }
    }
    .module-icon {
        position: relative;
        text-align: center;
        display: inline-block;  
        padding-top: 30px;
        margin: auto;
        padding-left: 0;

        &:before {
            content: '';
            width: 113px;
            height: 113px;
            border-radius: 50%;
            display: block;
            background-color: $white;
            opacity: 0.35;
            margin-top: 0;  
            margin-right: -30px; 
        }
        img, svg {
            position: absolute;
            top: 0px;
            bottom: auto;
            height: auto;
            //max-height: 65px;
            max-height: 84px;
            height: 84px;
            width: auto;
            display: block;
            text-align: left;
            margin-left: -8px;
        }
        //margin-bottom: calculateRem(20px);
    }
    .module-counter {
        margin-top: -40px;
        color: $blue;
        @include fontSize(90px);
        @include lineHeight(78px);  
        //@include font-family-poppins-semibold();       
        @include font-family-meta-bold();
    }
    .title {
        margin-top: calculateRem(10px);
        @include fontSize(18px);
        @include lineHeight(30px);       
        @include font-family-meta-normal();
    }    
    .module-icon {
        img, svg {}
    }  
}