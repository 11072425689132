@function calculateRem($size) {
  $remSize: $size / 16px;
  @return #{$remSize}rem;
}
@mixin fontSize($size) {
	font-size: $size; //Fallback in px
	font-size: calculateRem($size);
}
@mixin fontSizeImportant($size) {
	font-size: $size !important; //Fallback in px
	font-size: calculateRem($size) !important;
}
@mixin lineHeight($size) {
	line-height: $size; //Fallback in px
	line-height: calculateRem($size);
}