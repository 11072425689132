// -----------------------------------------------------------------------------
// This file contains all styles related to the infobox component.
// -----------------------------------------------------------------------------

.module--infobox {
    position: relative;
    padding: calculateRem(57px) calculateRem(90px) calculateRem(35px) calculateRem(90px);
    background-color: $bg-grey-dark;
    margin-top: 45px;

    @include media-breakpoint-down(sm) {
        padding: calculateRem(57px) calculateRem(30px) calculateRem(35px) calculateRem(30px);
    }

    .module-icon {
        width: 90px;
        height: 90px;
        background-color: $bg-grey-dark;
        position: absolute;
        left: calculateRem(16.5px);
        top: calculateRem(-45px);
        border-radius: 50%;
        border: 2px solid $white;
        text-align: center;
        opacity: 1;
        &:before {
            display: none;
        }
        img, svg {
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            width: auto;
            height: 55px; 
        }
    }

    .module-title + div {
        margin-top: calculateRem(28px);
    }
    .module-caption + .module-button {
        margin-top: calculateRem(15px);
    }    
    @include media-breakpoint-down(md) {
        word-break: break-word;
    }
}