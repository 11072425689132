// -----------------------------------------------------------------------------
// This file contains all styles related to the icons component.
// -----------------------------------------------------------------------------
.module--video {
    width: 100%;
    position: relative;
    padding-bottom: 56.25%;
    //padding-top: calculateRem(25px);
    height: 0;
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.accordion-body .module--video + .module--text {
    margin-top: calculateRem(25px);
}