// -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// ----------------------------------------------------------------------------

@mixin arrow($position: after, $direction: down) {
    
    &::#{$position} {
        display: inline-block;
        content: "";
        border-top: 0;
        border-right: 2px solid;
        border-bottom: 2px solid;
        border-left: 0;
        padding: 0.1875rem;
        transform: rotate(45deg);

        @if $direction == up {
            border-top: 2px solid;
            border-right: 0;
            border-bottom: 0;
            border-left: 2px solid;
            transform: rotate(45deg);
            position: relative;
            top: 5px;
        }

        @if $direction == left {
            border-left: 2px solid;
            border-top: 2px solid;
            border-right: 0;
            border-bottom: 0;
            transform: rotate(-45deg);
            position: relative;
            top: 0;
            margin-left: 8px;
        }

        @if $direction == right {
            border-left: 0;
            border-top: 0;
            border-right: 2px solid;
            border-bottom: 2px solid;
            transform: rotate(-45deg);
            position: relative;
            top: 0;
            margin-right: 8px;
        }

    }  
}

@mixin hyphens() {  
    -moz-hyphens: manual;
    -o-hyphens: manual;
    -webkit-hyphens: manual;
    -ms-hyphens: manual;
    hyphens: manual;   
    word-wrap: break-word; 
}

// Fonts
/*
@mixin font-family-open-sans-light() {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
}
@mixin font-family-open-sans-regular() {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
}
@mixin font-family-open-sans-medium() {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 500;
}
@mixin font-family-open-sans-semibold() {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
}
@mixin font-family-open-sans-bold() {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
}

@mixin font-family-poppins-light() {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
}
@mixin font-family-poppins-regular() {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
}
@mixin font-family-poppins-medium() {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
}
@mixin font-family-poppins-semibold() {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
}
*/

@mixin font-family-meta-normal() {
    font-family: 'ff-meta-web-pro', 'Meta-normal';
    font-style: normal;
    font-weight: 400;
}
@mixin font-family-meta-medium() {
    //font-family: 'Meta-normal';
    font-family: 'ff-meta-web-pro', 'Meta-normal';
    font-style: normal;
    font-weight: 400;
}
@mixin font-family-meta-semibold() {
    //font-family: 'Meta-normal';
    font-family: 'ff-meta-web-pro', 'Meta-normal';
    font-style: normal;
    //font-weight: 400;
    font-weight: 400;
}

@mixin font-family-meta-bold() {
    font-family: 'ff-meta-web-pro', 'meta-Bold';
    font-style: normal;
    //font-weight: 400;
    font-weight: 700;
}