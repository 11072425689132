// -----------------------------------------------------------------------------
// This file contains all styles related to the slider/swiper component.
// -----------------------------------------------------------------------------
.module--slider---x {
  margin-top: 0 !important;
  .swiper {
    width: 100%;
    
    height: 830px;

    &.h--800 {
      height: 800px;
    }
    &.h--600 {
      height: 613px;
    }    

    .swiper-button-prev, .swiper-button-next {
      display: none;
    }

    .swiper-pagination {
      text-align: right;

      left: 50%;
      transform: translateX(-50%);

      .swiper-pagination-bullet {

        width: 61px;
        height: 2px;
        background: $primary;
        opacity: 1;
        border-radius: 0;
        margin: 0  calculateRem(12px);
        &:last-child {
          margin-right: 1px;
        }
        &.swiper-pagination-bullet-active {
          background: $btn-primary;
        }
        @include media-breakpoint-down(sm) {
          width: 30px;
        }        
      }
    }

    .swiper-slide {

      .module--media {
        height: 100%;
        .figure {
            height: 100%;
            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }            
        }
      }

      .section-content {
        width: 100%;
        //height: 100%;
        position: absolute;
        left: 0;
        bottom: calculateRem(70px);

        .col {
          flex: 0 0 auto;
          width: 41.66667%;
          margin-left: 75.5%;

          @include media-breakpoint-down(sm) {
            width: 90%;
            margin-left: 5%;
          }

          
          .module--text {
            margin-left: calculateRem(-15px);
          }
        }
      }
    }
  }
}