// -----------------------------------------------------------------------------
// This file contains all styles related to the text-image component.
// -----------------------------------------------------------------------------

.module--text-image {
    position: relative;

    .module--media {

        position: relative;
        //margin-top: 70px;

        .media-inner {

            position: relative;
            .figure, a {
               // height: 100%; // ---
                img {
                    position: relative;
                }
            }
        }   

        //"offsets"
        &.pull {
            left: -20%;
            @include media-breakpoint-down(xl) {
                left: auto;
            }            
        }
        &.push {
            right: -20%;
            @include media-breakpoint-down(xl) {
                right: auto;
            }               
        }           
    }

    //Bild HG
    &.layout--1 {
        .module--media {
            @include media-breakpoint-down(lg) {
                //margin-top: calculateRem(80px);
                //margin-bottom: calculateRem(80px);
                margin-top: calculateRem(20px);
                margin-bottom: calculateRem(80px);                
            }
            @include media-breakpoint-down(md) {
                //margin-top: calculateRem(50px);
                //margin-bottom: calculateRem(50px);
                margin-top: calculateRem(20px);
                margin-bottom: calculateRem(50px);                
            }          
        }

        // Logo
        .module--media {
            position: relative;
            width: 100%;
            //height: 100%;
            height: auto;
            padding-top: 100%;
            .media-inner {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                .figure {
                    transform: translateY(-50%);
                    top: 50%; 
                }               
            }
            &.logo .media-inner .figure {
                img, svg {
                    max-width: calculateRem(250px);
                    //max-height: calculateRem(250px);
                }                    
            }
        }
    }
    
    //Bild bis zum Rand
    &.layout--2 {
        //background-color: #CCC;


        .module--media  {
            .figure {     
                width: 100%;
                height: auto;
                img {
                    min-width: 100%;
                    height: auto;
                }                
            }
            @include media-breakpoint-down(lg) {
                margin-top: calculateRem(80px);
                margin-bottom: calculateRem(80px);
            }
            @include media-breakpoint-down(md) {
                margin-top: calculateRem(50px);
                margin-bottom: calculateRem(50px);
            }            
        }

        //.module--media.is--left,
        .is--left {
            position: absolute;
            left: -15px;
            z-index: -5; 
            @include media-breakpoint-down(lg) {
                position: relative;
                left: auto;
            }            
        }
        //.module--media.is--right,
        .is--right {
            position: absolute;
            right: -15px;
            z-index: -5;            
            @include media-breakpoint-down(lg) {
                position: relative;
                right: auto;
            
            }             
        }   
        
        .module--text {
            &[class*='mt--'] {
                @include media-breakpoint-down(lg) {
                    margin-bottom: 0;
                }
            }
            @include media-breakpoint-down(lg) {
                &.mt--120, &.mt--200 {
                    margin-top: 0;
                }
            }
        }

        .module--media .arrow.right {
            @include media-breakpoint-down(sm) {
                right: 15px;
            }
        }
    }

    &.layout--1, &.layout--2 {
        .module--text {
            position: relative;
            z-index: 2;
        }
        .module-caption:not([class*='mt--']) {
            margin-top: calculateRem(40px);
        }
        
        .module-button {
            position: relative;
            z-index: 2;            
            @include media-breakpoint-down(lg) {
                margin-bottom: calculateRem(15px);
            }
        }   

    }

    //Text Rechts
    //        .col-12.col-sm-12.col-md-12.col-lg-6,
    &.layout--1, &.layout--2 {
        .col-12.col-sm-12.col-md-7.col-lg-7,
        .col-12.col-sm-12.col-md-12.col-lg-6.col-xl-7,
        .col-12.col-sm-12.col-md-12.col-lg-7.ms-lg-auto {
            .module--text {
                .module-pretitle {
                    padding-left: calc(9% + var(--bs-gutter-x)*1);
                }              
                .module-caption,
                .module-button {
                    @include media-breakpoint-up(lg) {
                        padding-left: calc(10.6% + var(--bs-gutter-x)*1);
                    }
                }               
            }    
        }          
    }    

    // Newsdetail Author
    &.author {
        .module--media {
            margin-top: 50px;
            @include media-breakpoint-down(lg) {
                margin-top: calculateRem(80px);
                margin-bottom: calculateRem(80px);
            }
            @include media-breakpoint-down(md) {
                margin-top: calculateRem(50px);
                margin-bottom: calculateRem(50px);
            }              
            &.push {
                right: 5%;
                @include media-breakpoint-down(xl) {
                    right: auto;
                }                     
            }         
        }
    }
}

.txt-img.bg--blue-gradient {
    //padding: calculateRem(150px) calculateRem(90px);
    padding: calculateRem(150px) 0;
    @include media-breakpoint-down(md) {
        padding: calculateRem(75px) 0;
    }
}