// -----------------------------------------------------------------------------
// This file contains all styles related to the to-start component.
// -----------------------------------------------------------------------------
.scroll-to-start {
    cursor: pointer;
    display: inline-block;
    transform: rotate(90deg);
    z-index: 5;
    
    width: 60px;
    height: 60px;
    &:hover > .arrow.right {
        > svg {
        position: relative;
        animation: bounce-hor 1.2s linear infinite;
        }
    }

    // slider
    &.align-left {
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 10;
        
        left: calculateRem(126px);
        bottom: calculateRem(-43px);

        &.layout--2 {
            bottom: calculateRem(-43px);
        }        

        @include media-breakpoint-down(xl) {
            left: calculateRem(70px);
            bottom: 0;
        }
        @include media-breakpoint-down(lg) {
            left: calculateRem(10px);
            bottom: auto;
            top: calculateRem(450px);
        }    
        @include media-breakpoint-down(md) {
            top: calculateRem(350px);
        }  
        @include media-breakpoint-down(sm) {
            top: calculateRem(250px);
        }     
        @include media-breakpoint-down(md) {
            display: none;   
        }
    }

    // herobild
    &.align-center {
        margin: calculateRem(67px) auto 0 auto;
    }
}


