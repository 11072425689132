// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

.is--fullwidth {
  width: 100vw;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  position: relative;
  overflow-x: hidden;
}

.site-main {
  //overflow-x: hidden;
  overflow: clip;
  width: 100%;
  padding-top: calculateRem(172px); // sticky header

  @include media-breakpoint-down(lg) {
    padding-top: calculateRem(144px); // sticky header
  }

  @include media-breakpoint-down(md) {
    padding-top: calculateRem(144px); // sticky header
  }

  @include media-breakpoint-down(sm) {
    padding-top: calculateRem(112px); // sticky header
  }
  
  .container + .section-content:not([class*='mt--']),
  > div > .section-content:first-child:not([class*='mt--']){    
    margin-top: calculateRem(200px);
    @include media-breakpoint-down(md) {
      margin-top: calculateRem(100px);
    }
    @include media-breakpoint-down(sm) {
      margin-top: calculateRem(50px);
    }    
  } 

  .news-single .article > .section-content:first-child:not([class*='mt--']) {
    margin-top: calculateRem(200px);
    @include media-breakpoint-down(md) {
      margin-top: calculateRem(100px);
    }
    @include media-breakpoint-down(sm) {
      margin-top: calculateRem(50px);
    }    
  }   
  /*
  .section-content:last-child:not([class*='mb--']) {
    margin-bottom: 200px; 
    @include media-breakpoint-down(md) {
      margin-bottom: calculateRem(100px);
    }
    @include media-breakpoint-down(sm) {
      margin-bottom: calculateRem(50px);
    } 
  } */

   > #content {
    height: auto;
    @include media-breakpoint-up(xxl) {
      min-height: calculateRem(2500px);
    }
  } 
}

body.pid167 {
  .site-main {
    > #content {
      @include media-breakpoint-up(xxl) {
        min-height: auto;
      }      
    }
  }
}

#main-content {
  //display: inline-block;
}


/*
.section-content + .section-content:not([class*='mt--']) {
  margin-top: 200px; 
  @include media-breakpoint-down(md) {
    margin-top: calculateRem(100px);
  }
  @include media-breakpoint-down(sm) {
    margin-top: calculateRem(50px);
  }   
}
*/

.site-main {
  .section-content:not([class*='mb--']) {
    margin-bottom: 200px; 
    @include media-breakpoint-down(md) {
      margin-bottom: calculateRem(100px);
    }
    @include media-breakpoint-down(sm) {
      margin-bottom: calculateRem(50px);
    }   
  }

  .sidebar {
    .section-content:not([class*='mb--']) {
      margin-bottom: 0; 
      @include media-breakpoint-down(md) {
        margin-bottom: 0;
      }
      @include media-breakpoint-down(sm) {
        margin-bottom: 0;
      }   
    }
  }
}

.site-main .news-detail .section-content:not([class*='mb--']) {
  margin-bottom: 0; 
}

div[class*="col-"] > .module + .module:not([class*='mt--']),
.news-detail > .col-12 > div + div:not([class*='mt--'])  {
  margin-top: calculateRem(100px);
  @include media-breakpoint-down(md) {
    margin-top: calculateRem(100px);
  }
  @include media-breakpoint-down(md) {
    margin-top: calculateRem(50px);
  } 
} 


.site-main > div > .section-content > .container > .row >  {
    @include media-breakpoint-down(md) {
      div > .module:not(.module--accordion):not([class*='mb--']) {
        margin-bottom: calculateRem(50px);
      }
      div:last-child > .module:not([class*='mb--']) {
        margin-bottom: 0;
      }      
    }
}


.container-fullwidth,
.section-content {
  //max-width: 100%;
  //position: relative;
  //--overflow-x: hidden; 
  max-width: 100vw;
  position: relative;
  overflow-x: clip;  
}

.section-content.bg-blue-gradient.left  {
  margin-bottom: 0;
}
.section-content.bg-blue-gradient.left  + .section-content  {
  margin-bottom: 0; 
}

.section-content.bg-blue-gradient {
  height: 45px;
  width: 100%;
  //background-color: $body-bg;
  position: relative;
  &:before {
    content: '';
    display: inline-block;
    height: 100%;
    width: 100%;
    position: absolute;
  }
  &.left:before {
    background: $bg-linear-gradient;
    transform: rotate(180deg);
    max-width: calculateRem(733px);
    left: 0;
  }
  &.right:before {
    background: $bg-linear-gradient;
    max-width: calculateRem(1590px);
    right: 0;
  }
}

.bg--white {
  background: $white;
}


.bg--grey-light {
  background: $bg-grey-light;
  padding-top: calculateRem(130px);
  padding-bottom: calculateRem(130px); 

  @include media-breakpoint-down(md) {
    padding-top: calculateRem(65px);
    padding-bottom: calculateRem(65px);
  }
}
.bg--grey-dark {
  background-color: $bg-grey-dark;
  padding-top: calculateRem(130px);
  padding-bottom: calculateRem(130px);

  @include media-breakpoint-down(md) {
    padding-top: calculateRem(65px);
    padding-bottom: calculateRem(65px);
  }
}

.bg--blue-gradient {
  background: $bg-linear-gradient-2;
}

.bg--blue-gradient-2 {
  background: $bg-linear-gradient-4;
  //padding: calculateRem(100px) calculateRem(90px); // ??
  padding: calculateRem(100px) 0; // zb. Downloads

  @include media-breakpoint-down(md) {
    padding-top: calculateRem(50px);
    padding-bottom: calculateRem(50px);
  }
}



.section-content {

  > .bg--img {

    width: calculateRem(540px);
    height: calculateRem(1006px);
    overflow: visible;
    //height: calculateRem(1261px);
    position: absolute;
    right: 0;
    top: 20%;
    background-size: auto 100%;
    background-repeat: no-repeat;
    //background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIGlkPSJFYmVuZV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMTUxLjg2MzQgMTAwNi4yODE4Ij48cGF0aCBkPSJtMTAwLjY4NjksMzA1LjUzODVDMzI3LjY5MTksMjcuNzc5NSw1MjMuNDIyOS0xMi4xMjE1LDU5Mi45MTIsMi42NDg1YzI1MS45NTcsNTEuNDE2LDQ0NS44ODQ5LDM3Mi4yMjMsNDc0LjYwNSw1MjMuMzE5LDI4LjcyLDE1MS4wOTUtNDQuMDMsMzQ2LjYzOC0yNzAuNzY0LDM5OS41MzgtMjI2LjczMSw1Mi45MS01MTguOTU2LTk3LjAxMS01NDcuODMtMTE0LjgzOC0yOC44NzQtMTcuODI3LTQzMS45OTE4LTE1Ny45MzAxLTE0OC4yMzYtNTA1LjEyOVoiIGZpbGw9IiNmNWY1ZjUiLz48cGF0aCBkPSJtNTEzLjY2NTksMTAwNi4yODE4Yy0yNi4zMDc2LDAtNTEuMDcxMy00LjI3MTUtNzQuMTUzMy0xMi44MjEzLTc1LjgxNTQtMjguMDgzLTEzMy4xMTUyLTEwMi4zMDg2LTE3MC4zMDc2LTIyMC42MTMzLTEwMS4zOTc1LTMyMi41MzIyLTMzLjY3NDgtNTAxLjMxNjQsMTYuMzAxOC01NTAuNzlDNDU4LjcwNCw0Ny43ODkyLDgxNS44OTE1LDYxLjY3ODMsOTUwLjg2ODEsMTE3LjY1ODhjNzIuMDQ3OSwyOS44ODIzLDEzMy4yNTg4LDg2LjU0MywxNjcuOTM5NSwxNTUuNDUzNiw0MC44MTI1LDgxLjA5NTcsNDMuODkyNiwxNzQuMzQyOCw4LjkwOTIsMjY5LjY2MDYtMTcuNTQ3OSw0Ny44MDU3LTQ2LjkwMjMsOTYuNTMzMi04Ny4yNDgsMTQ0LjgzMDEtMzIuODc3OSwzOS4zNTc0LTczLjA4NjksNzguNTIyNS0xMTkuNTExNywxMTYuNDA3Mi03Ni45MjY4LDYyLjc3NjQtMTUyLjUyNzMsMTA2LjQwNzItMTcxLjM2MzMsMTE1LjE5ODItMi41NTU3LDEuMTkzNC03LjQ0MTQsNC4yMzM0LTE0LjIwNDEsOC40NDI0LTIzLjM0MzgsMTQuNTI4My02Ni43OTU5LDQxLjU3MjMtMTIwLjE4MDcsNjAuMDE4Ni0zNS44OTY1LDEyLjQwNDMtNjkuODM5OCwxOC42MTIzLTEwMS41NDMsMTguNjEyM1ptLTI0My41MDc4LTIzMy43MzU0YzM3LjA5NTcsMTE3Ljk5NTEsOTQuMTkxNCwxOTIuMDA1OSwxNjkuNzAxMiwyMTkuOTc1NiwxMTguNzM2Myw0My45ODYzLDI1MS40MDUzLTM4LjU4NjksMjk1LjAwMi02NS43MTk3LDYuNzkyLTQuMjI3NSwxMS42OTkyLTcuMjgxMiwxNC4zMDk2LTguNSwxMS41ODU5LTUuNDA3Miw4Ny45Mzg1LTQ3LjE1ODIsMTcxLjE1NDMtMTE1LjA2NjQsNzIuMTA5NC01OC44NDU3LDE2Ni40NjA5LTE1MS44NjA0LDIwNi40NTIxLTI2MC44MDY2LDczLjM0NDctMTk5LjgzNTktMzMuMTY4OS0zNjQuNDg1NC0xNzYuMjkyLTQyMy44NDY3LTEzNC43NjM3LTU1Ljg5MjEtNDkxLjM3NzktNjkuNzgxMi02NjQuMjcyNSwxMDQuMTgyNi00OS44MjkxLDQ5LjMyNzYtMTE3LjMwNjYsMjI3LjcxLTE2LjA1NDcsNTQ5Ljc4MTJaIiBmaWxsPSIjMDA0ODc1Ii8+PC9zdmc+');
    //mit linie
    //background-image: url(' data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIGlkPSJFYmVuZV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMTUxLjg3NzkgMTAwNi4yODUiPjxwYXRoIGQ9Im0xMDAuNjg2OSwzMDUuNTM4NUMzMjcuNjkxOSwyNy43Nzk1LDUyMy40MjI5LTEyLjEyMTUsNTkyLjkxMiwyLjY0ODVjMjUxLjk1Nyw1MS40MTYsNDQ1Ljg4NDksMzcyLjIyMyw0NzQuNjA1LDUyMy4zMTksMjguNzIsMTUxLjA5NS00NC4wMywzNDYuNjM4LTI3MC43NjQsMzk5LjUzOC0yMjYuNzMxLDUyLjkxLTUxOC45NTYtOTcuMDExLTU0Ny44My0xMTQuODM4LTI4Ljg3NC0xNy44MjctNDMxLjk5MTgtMTU3LjkzMDEtMTQ4LjIzNi01MDUuMTI5WiIgZmlsbD0iI2Y1ZjVmNSIvPjxwYXRoIGQ9Im0yNjkuNjgxOSw3NzIuNjk2NWMtMTAyLjQ3OC0zMjUuOTY4LTMxLjkxNS01MDIuNjc2LDE2LjE3Ni01NTAuMjg0QzQ1OC41MjU5LDQ4LjY3NzUsODE1LjM1MDksNjEuOTk0NSw5NTAuNjc2OSwxMTguMTIwNWMxMzUuMzIwMSw1Ni4xMjUsMjUyLjk4LDIxNi4yOTIsMTc2LjU3MDEsNDI0LjQ4MS03Ni40MiwyMDguMTg5LTM0OC41NzQsMzYyLjQ4NDEtMzc3Ljg2NCwzNzYuMTU0LTI5LjI5MSwxMy42Ny0zNTEuNjA0LDI2MS40LTQ3OS43MDEtMTQ2LjA1OVoiIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzAwNDg3NSIvPjwvc3ZnPg==');
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMTUxLjg3OCAxMDA2LjI4NSI+PHBhdGggZmlsbD0iI2Y1ZjVmNSIgZD0iTTEwMC42ODcgMzA1LjUzOEMzMjcuNjkyIDI3Ljc4IDUyMy40MjMtMTIuMTIyIDU5Mi45MTIgMi42NDljMjUxLjk1NyA1MS40MTcgNDQ1Ljg4NSAzNzIuMjI0IDQ3NC42MDUgNTIzLjMyIDI4LjcyIDE1MS4wOTUtNDQuMDMgMzQ2LjYzNy0yNzAuNzY0IDM5OS41MzgtMjI2LjczMSA1Mi45MS01MTguOTU2LTk3LjAxMS01NDcuODMtMTE0LjgzOC0yOC44NzQtMTcuODI3LTQzMS45OTItMTU3LjkzLTE0OC4yMzYtNTA1LjEzeiIvPjxwYXRoIGZpbGw9Im5vbmUiIHN0cm9rZT0iI0NEMTQzMiIgZD0iTTI2OS42ODIgNzcyLjY5N2MtMTAyLjQ3OC0zMjUuOTY5LTMxLjkxNS01MDIuNjc2IDE2LjE3Ni01NTAuMjg0QzQ1OC41MjYgNDguNjc3IDgxNS4zNSA2MS45OTUgOTUwLjY3NyAxMTguMTJjMTM1LjMyIDU2LjEyNiAyNTIuOTggMjE2LjI5MyAxNzYuNTcgNDI0LjQ4MS03Ni40MiAyMDguMTktMzQ4LjU3NCAzNjIuNDg1LTM3Ny44NjQgMzc2LjE1NC0yOS4yOTEgMTMuNjctMzUxLjYwNCAyNjEuNC00NzkuNzAxLTE0Ni4wNTl6Ii8+PC9zdmc+');
    
    background-position: top left;   
    z-index: -1;  

    
    @include media-breakpoint-down(xl) {
      width: calculateRem(400px);
      background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIGlkPSJFYmVuZV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMTUxLjg3NzkgMTAwNi4yODUiPjxwYXRoIGQ9Im0xMDAuNjg2OSwzMDUuNTM4NUMzMjcuNjkxOSwyNy43Nzk1LDUyMy40MjI5LTEyLjEyMTUsNTkyLjkxMiwyLjY0ODVjMjUxLjk1Nyw1MS40MTYsNDQ1Ljg4NDksMzcyLjIyMyw0NzQuNjA1LDUyMy4zMTksMjguNzIsMTUxLjA5NS00NC4wMywzNDYuNjM4LTI3MC43NjQsMzk5LjUzOC0yMjYuNzMxLDUyLjkxLTUxOC45NTYtOTcuMDExLTU0Ny44My0xMTQuODM4LTI4Ljg3NC0xNy44MjctNDMxLjk5MTgtMTU3LjkzMDEtMTQ4LjIzNi01MDUuMTI5aDBaIiBmaWxsPSIjZjVmNWY1Ii8+PC9zdmc+') !important;
    }


    @include media-breakpoint-down(lg) {
      display: none;
    }
    
    &.news {
      top: calculateRem(800px);
      //ohne linie
      background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIGlkPSJFYmVuZV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDczLjQ3MjUiIGhlaWdodD0iOTM2Ljc1NjMiIHZpZXdCb3g9IjAgMCAxMDczLjQ3MjUgOTM2Ljc1NjMiPjxwYXRoIGQ9Ik0xMDAuNjg3MSwzMDUuNTM3N0MzMjcuNjkyMSwyNy43Nzk4LDUyMy40MjMxLTEyLjEyMjIsNTkyLjkxMjEsMi42NDg4YzI1MS45NTcsNTEuNDE3LDQ0NS44ODUsMzcyLjIyNCw0NzQuNjA1LDUyMy4zMiwyOC43MiwxNTEuMDk1LTQ0LjAzLDM0Ni42MzctMjcwLjc2NCwzOTkuNTM4LTIyNi43MzEsNTIuOTEtNTE4Ljk1Ni05Ny4wMTEtNTQ3LjgzLTExNC44MzgtMjguODc0LTE3LjgyNy00MzEuOTkyLTE1Ny45My0xNDguMjM2LTUwNS4xM3YtLjAwMDlaIiBmaWxsPSIjZjVmNWY1Ii8+PC9zdmc+');
    }
    &.location {
      //top: 20%;
      top: calculateRem(400px);
    } 
    &.services {
      top: 0;
      //ohne linie
      background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIGlkPSJFYmVuZV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDczLjQ3MjUiIGhlaWdodD0iOTM2Ljc1NjMiIHZpZXdCb3g9IjAgMCAxMDczLjQ3MjUgOTM2Ljc1NjMiPjxwYXRoIGQ9Ik0xMDAuNjg3MSwzMDUuNTM3N0MzMjcuNjkyMSwyNy43Nzk4LDUyMy40MjMxLTEyLjEyMjIsNTkyLjkxMjEsMi42NDg4YzI1MS45NTcsNTEuNDE3LDQ0NS44ODUsMzcyLjIyNCw0NzQuNjA1LDUyMy4zMiwyOC43MiwxNTEuMDk1LTQ0LjAzLDM0Ni42MzctMjcwLjc2NCwzOTkuNTM4LTIyNi43MzEsNTIuOTEtNTE4Ljk1Ni05Ny4wMTEtNTQ3LjgzLTExNC44MzgtMjguODc0LTE3LjgyNy00MzEuOTkyLTE1Ny45My0xNDguMjM2LTUwNS4xM3YtLjAwMDlaIiBmaWxsPSIjZjVmNWY1Ii8+PC9zdmc+');
    }        
  }
}

hr {
  border-color: $border-grey-light;
}

.ps-4 {
    padding-left: 2rem;
}
  
.bg--blue {
   // background-color: $blue;   
}
  

.pr-md-4 {
  @include media-breakpoint-up(md) {
    padding-right: 2rem;
  }
}
.pl-md-4 {
  @include media-breakpoint-up(md) {
    padding-left: 2rem;
  }
}

.mb--55 {
  margin-bottom: 55px;
}

$spaceamounts: (0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 160, 170, 180, 190, 200); 
$sidehorz: (top, bottom);
@each $spaceh in $spaceamounts {
  @each $sideh in $sidehorz {
    
    .m#{str-slice($sideh, 0, 1)}--#{$spaceh} {
      
      margin-#{$sideh}: #{$spaceh}px;
      
      @include media-breakpoint-down(lg) { 
      //  margin-#{$sideh}: #{$spaceh*0.5}px;
      }

      @if ($spaceh > 80) {
        @include media-breakpoint-down(md) { 
          margin-#{$sideh}: #{$spaceh*0.5}px;
        }
        @include media-breakpoint-down(sm) { 
          margin-#{$sideh}: #{$spaceh*0.25}px;
        }
      }  
    }  
  }
}
  

@keyframes bounce-hor {
    0%, 100% {
      transform: translateX(0);
    }
    25% {
      transform: translateX(3px);
    }
    75% {
      transform: translateX(-3px);
    }  
  }
    
  

@keyframes bounce-ver {
  0%, 100% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-3px);
  }
  75% {
    transform: translateY(3px);
  }  
}