// -----------------------------------------------------------------------------
// This file contains all styles related to the slider/swiper component.
// -----------------------------------------------------------------------------
.module--slider {

  // Pageheader
  &.hero {
    margin-top: 0 !important;
    .swiper {
      width: 100%;
      height: 830px;

      &.h--800 {
        height: 800px;
      }
      &.h--600 {
        height: 613px;
      } 
      
      &.layout--2 {
        height: 613px;
      } 
      &.layout--3 {
        height: 800px;
      }

      @media (min-width: 1701px) and (max-width: 1800px) {
        height: 700px;
      }  
      @media (min-width: 1400px) and (max-width: 1700px) {
        height: 600px !important;
      }  
      @include media-breakpoint-down(xxl) {
        height: 500px !important;
      }
      @include media-breakpoint-down(xl) {
        //height: 450px !important;
        height: 520px !important;
      }
      @include media-breakpoint-down(lg) {
        //height: 780px !important;
        height: auto !important;
      }
      @include media-breakpoint-down(md) {
        //height: 700px !important;
        height: auto !important;
      }
      @include media-breakpoint-down(sm) {
        //height: 580px !important;
        height: auto !important;
      }      
  
      .swiper-button-prev, .swiper-button-next {
        display: none;
      }

      .swiper-slide {


        @include media-breakpoint-down(lg) {
          align-content: space-between !important;
          flex-wrap: wrap !important;
          display: flex !important;
          align-self: stretch;
          height: auto;
        }

        .module--media {
          height: 100%;
          width: auto;
          display: inline-block;
          //-->
          background: #FFF !important;
          width: 100%;
          overflow: hidden;

          @media (min-width: 1701px) and (max-width: 1800px) {
            height: 700px;
          }  
          @media (min-width: 1400px) and (max-width: 1700px) {
            height: 600px;
          }  
          @include media-breakpoint-down(xxl) {
            height: 500px;
          }
          @include media-breakpoint-down(xl) {
            height: 400px;
          }
          @include media-breakpoint-down(lg) {
            height: 450px;
          }    
          @include media-breakpoint-down(md) {
            height: 350px;
          }
          @include media-breakpoint-down(sm) {
            height: 250px;
          }          

          &:after {
            content:'';
            position: absolute;
            left: 0;
            top: 0;
            display: block;
            width: 100%;
            height: 100%;
            //1920 x 830 ??
            background: url(' data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIGlkPSJFYmVuZV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxOTIwIDgzMCI+PHBhdGggZD0ibTk1OSw3MzNDMTI0Ny42ODAxLDU3My4yNTIsMTM0NS43MiwxODcuMTM0LDEzNTksMGg1NjF2OTA3LjVIMHYtMTc1LjI4MmMzODIuODcxLDEwMC40NDksNjgxLjM0MywxNTQuNDI5LDk1OSwuNzgyWiIgZmlsbD0iI2ZmZiIvPjwvc3ZnPg==');            
            background-size: auto 100%;
            background-repeat: no-repeat;
            background-position: 0 100%;
          }

          .figure {
              height: 100%;
              width: auto;
              display: inline-block;
              overflow: hidden;
              
              &:after {
                //content: '';
                position: absolute;
                left: 0;
                top: 0;
                display: block;
                width: 100%;
                height: 100%;
                //1360 x 830 ??
                background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIGlkPSJFYmVuZV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMzYwLjA4NTEgODMwLjEyNzciPjxwYXRoIGQ9Im05NTksNzMzQzEyNDcuNjgwMSw1NzMuMjUyLDEzNDUuNzIsMTg3LjEzNCwxMzU5LDBoNTYxdjkwNy41SDB2LTE3NS4yODJjMzgyLjg3MSwxMDAuNDQ5LDY4MS4zNDMsMTU0LjQyOSw5NTksLjc4MloiIGZpbGw9IiNmZmYiLz48L3N2Zz4=');
                background-size: auto 100%;
                background-repeat: no-repeat;
                background-position: left bottom;
                border-right: 2px solid #FFF;
                //display: none;
              }

              img {
                  height: 100%;
                  width: 100%;
                  object-fit: cover;
              }            
          }
        }

        .section-content {
          width: 100%;
          position: absolute;
          left: 0;
          //bottom: calculateRem(70px);
          bottom: calculateRem(90px);
          margin-bottom: 0;

          @include media-breakpoint-down(lg) {
            position: relative;
            bottom: auto;
            margin-top: calculateRem(40px);
            margin-bottom: calculateRem(40px);

          }
          @include media-breakpoint-down(md) {
            position: relative;
            bottom: auto;
            margin-top: calculateRem(40px);
            margin-bottom: calculateRem(40px);
            width: 100%;
            height: 100%;
            
          }

          .d-flex {
            justify-content: flex-end !important;
          }
          
          .col {
            flex: 0 0 auto;
            width: 41.66667%;
            @include media-breakpoint-up(xxl) {
              margin-right: calc(-16.66667% + 1.9rem);
            }
            @media (min-width: 1400px) and (max-width: 1700px) {
              margin-right: -5%;
            }              
            @include media-breakpoint-down(xxl) {
              width: 35%;
            }
            @include media-breakpoint-down(xl) {
              width: 35%;
            }
            @include media-breakpoint-down(lg) {
              width: 95%;
            }              
            @include media-breakpoint-down(md) {
              width: 100%;
            }              
            @include media-breakpoint-down(sm) {
              //width: 90%;
              width: 100%;
            }  
          }
        }
      }

      &.layout--default {
        .title {
          @include media-breakpoint-only(lg) {
            @include fontSize(32px);
            @include lineHeight(42px);
         }
         @include media-breakpoint-down(sm) {
          @include fontSize(26px);
          @include lineHeight(35px);
         }
       }
      }



      &.layout--2 {
        //.swiper-slide .section-content .col   { }
      }   
      &.layout--3 {
        .swiper-slide .section-content .col,
        .slider-pagination .col   {
          @include media-breakpoint-up(xxl) {
          //  margin-right: -25%;
          }
          @include media-breakpoint-down(xxl) {
          //  width: 30%;
          }    
        }
      }  

      .slider-pagination {
        z-index: 100;
        position: relative;
        top: -10px;

        .d-flex {
          justify-content: flex-end !important;
        }
        
        .col {
          position: relative;
          flex: 0 0 auto;
          width: 41.66667%;
          @include media-breakpoint-up(xxl) {
            margin-right: calc(-16.66667% + 1.9rem);
          }
          @media (min-width: 1400px) and (max-width: 1700px) {
            margin-right: -5%;
          } 

          @include media-breakpoint-down(xxl) {
            //width: 40%;
            width: 35%;
          }
          @include media-breakpoint-down(lg) {
            width: 94.3%;
            //width: 100%;
          }             
          @include media-breakpoint-down(md) {
            //width: 94.3%;
            width: 100%;
          }            
          @include media-breakpoint-down(sm) {
            //width: 89%;
            width: 99%;
          }           
        }        
      } 
    }
  }


  // bullets
  .swiper-pagination {
    text-align: left;
    left: calculateRem(80px);
   
    .swiper-pagination-bullet {

      //width: 61px;
      width: 50px;
      height: 6px;
      //border-radius: 0;
      border-radius: 50rem;
      background: $primary;
      overflow: hidden;
      opacity: 1;
      margin: 0  calculateRem(12px);
      position: relative;

      &:last-child {
        margin-right: 1px;
      }

      &:before {
        background: $btn-primary;
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        transform-origin: right;
        transform: scaleX(0);
      }

      &.swiper-pagination-bullet-active {
        
        &:before {
          transition-delay: 1s;
          transition: transform 6s ease-in;
          transform-origin: left;
          transform: scaleX(1);
        }

      }
      @include media-breakpoint-down(sm) {
        width: 50px;
      }   
    }
  }


  //swiper-btn-pause
  .swiper-control {
    position: absolute;
    left: 1rem;
    top: calculateRem(-51px);
    width: calculateRem(57px);
    height: calculateRem(57px);
    @include media-breakpoint-down(sm) {
      width: calculateRem(57px);
      height: calculateRem(57px);
    }
    display: inline-block;
    button.swiper-btn-pause, 
    button.swiper-btn-play {
      background: transparent;
      border: 0;
      width: 100%;
      height: 100%;
      margin: 0 0;
      padding: 0 0;
      position: absolute;
      left: 0;
      top: 0;

      svg {
        width: 100%;
        height: 100%;        
        path {
            //stroke: $btn-secondary;
            //fill: $primary;
        } 
      } 

      span.pause {
        display: block;
      }
      span.play {
        display: none;
      }

      &.is--paused {
        span.pause {
          display: none;
        }
        span.play {
          display: block;
        } 
      }   
    }
  }    

  //Contentslider / Zitate
  &.carousel {
    height: auto;
    .swiper.carousel {
      @include media-breakpoint-down(md) {
        //padding-bottom: calculateRem(60px);
        padding-bottom: calculateRem(80px);
        height: auto;

        width: 100vw;
        position: relative;
        left: 50%;
        transform: translateX(-50%);

      }

      .swiper-control {
        top: 0;
      }
      .swiper-pagination {
        left: calculateRem(80px);
        .swiper-pagination-bullet {
          width: 35px;
          height: 5px;
        }
      }

    }

    .swiper-slide {
      align-self: stretch;
      height: auto;
      .row {
        // height: 100%;
      }
    }

    // Pfeile
    > .module--media {
      position: absolute;
      z-index: 2;
      right: 0;
      bottom: calculateRem(578px);

      @include media-breakpoint-down(xxl) {
        bottom: calculateRem(518px);
      }
      @include media-breakpoint-down(xl) {
        bottom: 0;
      }   
      @include media-breakpoint-down(md) {
        bottom: auto;
        top: 0; 
      }
      @include media-breakpoint-up(xl) {
        .arrow.right.top {
          right: 65px;
          top: 65px;
        }
      }
      @include media-breakpoint-down(sm) {
        .arrow.right.top { 
          right: 15px;
          top: auto;
        }
      }
    }

    .carousel-navi, .carousel-pagination  {
      width: calc(50% - var(--bs-gutter-x)*0.5);
      position: absolute;
      right: 0;
      top: calculateRem(115px);
      z-index: 20;
      @include media-breakpoint-down(lg) {
        width: calc(41% - var(--bs-gutter-x)*0.5);
      }
      @include media-breakpoint-down(md) {
        //top: 0;
        top: auto;
        //bottom: 0;
        right: auto;
        width: 100%;
        left: 100%;
        //margin-left: calculateRem(-240px);
        margin-left: -100%;
        margin-top: calculateRem(10px);
      }
    }

    .swiper-button-prev, .swiper-button-next {
      width: 45px;
      height: 45px;
      margin: 0 0;
      &:after {
        content:'';
      }
      .arrow > svg {
        width: 20px;
        height: 100%;
      }
    }
    .swiper-button-prev {
      left: 0;
      transform: rotate(180deg);
    }
    .swiper-button-next {
      right: auto;
      left: calculateRem(75px);
    }

    .swiper-pagination {
      @include fontSize(36px);
      @include lineHeight(45px);  
      //@include font-family-open-sans-regular();
      @include font-family-meta-normal();
      color: $body-color;
      letter-spacing: -4px;

      width: auto;
      position: absolute;
      top: 0;
      left: calculateRem(145px);
    }
      
    .swiper-slide {
      .row {
        position: relative;
        height: auto;
        height: 100%;

        @include media-breakpoint-down(md) {
          flex-direction: column;
          justify-content: center;
        }
        
        // Text/Bild
        .col-12:first-child {
          z-index: 2;
          mix-blend-mode: multiply;
          display: flex !important;
          align-items: flex-start !important;
          > div {
            //margin-bottom: calculateRem(145px);
            margin-bottom: calculateRem(110px);
            @include media-breakpoint-down(xl) {
              margin-bottom: calculateRem(100px);
            }  
            @include media-breakpoint-down(md) {
              margin-bottom: 0;
            }
          }
          @include media-breakpoint-down(md) {
            flex: 1;
          }
        }

        // Bild
        .col-12:last-child {
          display: flex !important;
          align-items: flex-end !important;
          .module.module--media {
            margin-left: -17.5%;
            margin-top: calculateRem(190px);
            @include media-breakpoint-down(lg) {
              margin-left: -23%;
              margin-top: calculateRem(145px);
            }
            @include media-breakpoint-down(md) {
              margin-top: 0;
            }
            @include media-breakpoint-down(md) {
              margin-left: 0;
              margin-top: 0;
              display: block;
              width: 100%;
              * {
                width: 100%;
              }
            }
          }
        }  

      }
      .module--text {
        height: auto;
        //min-height: calculateRem(640px);
        @include media-breakpoint-up(md) {
          //min-height: calc(100% - 145px);
          min-height: calc(100% - 110px);
        }
        @include media-breakpoint-down(md) {
          height: 100%;
        }
        width: 100%;
        //padding: calculateRem(95px) calculateRem(33px) calculateRem(80px) calculateRem(33px); 
        padding: calculateRem(70px) calculateRem(55px) calculateRem(50px) calculateRem(33px); 
        position: relative;

        @include media-breakpoint-down(xl) {
          padding: calculateRem(45px) calculateRem(55px) calculateRem(33px) calculateRem(33px); 
        }      
        @include media-breakpoint-down(lg) {
          padding: calculateRem(30px) calculateRem(35px) calculateRem(30px) calculateRem(30px); 
        }        
        @include media-breakpoint-down(md) {
          padding: calculateRem(30px) calculateRem(30px) calculateRem(30px) calculateRem(30px); 
        }
        &:before {
          position: absolute;
          content: '';
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          display: block;
          background: $bg-linear-gradient-3;
          transform: rotate(-180deg);
          z-index: -1;
        }
        div {
          width: 100%;
        }



        .module-title .title:before {
          content: '“';
          position: relative;
          display: inline-block;
        }
        .module-title .title:after {
            content: '”';
            position: relative;
            display: inline-block;
        }
        @include media-breakpoint-down(xl) {
          .module-title + .module-caption {
            margin-top: calculateRem(15px) !important;
          }      
        }
        @include media-breakpoint-down(lg) {
          .module-title .title {
            @include fontSize(22px);
            @include lineHeight(35px);  
          }        
        }
        @include media-breakpoint-down(md) {      
          .module-button {
            margin-top: calculateRem(15px) !important;
          }
        }

      }
      .module--media {
        .figure {
          img {
              height: 100%;
              width: 100%;
              object-fit: cover;
          }            
        }
      }
    }
  }

  &.reference {

    //margin-top: 0 !important;
    //@include media-breakpoint-down(md) {
    //  margin-top: 0 !important;
    //}

    .swiper.reference {
      padding-top: calculateRem(157px);
      @include media-breakpoint-down(md) {
        padding-top: calculateRem(107px);
      }
    }

    .swiper > .swiper-wrapper {
      transition-timing-function : linear !important;
      align-items: center; 
    }
    .swiper-slide {
      vertical-align: middle;
      text-align: center;
      //height: 100px;
      img {
        //max-height: 100%;
        max-height: calculateRem(90px);
        @include media-breakpoint-down(sm) {  
          max-height: calculateRem(70px);
        }
        max-width: 100%;
        width: auto;
        height: auto !important;
      }
    }

    .slider-pagination {
      position: relative;
      top: calculateRem(-157px);
      @include media-breakpoint-down(md) {
        top: calculateRem(-107px);
      }
      .swiper-control{
        left: auto;
        right: $grid-gutter-width / 2;
        top: 0;
      }
    }

  }
}


//Slider
.section-content.module.module--slider.layout--2 + .news {
  margin-top: calculateRem(80px) !important;
}
//Bild
.section-content.module.module--hero + .news {
  margin-top: calculateRem(10px) !important;
}

.container + .module.module--slider.reference,
.module.module--text + .module.module--slider.reference {
  margin-top: -55px !important;
  @include media-breakpoint-down(xl) {
    margin-top: -50px !important;
  }
  @include media-breakpoint-down(lg) {
    margin-top: -45px !important;
  }
  @include media-breakpoint-down(md) {
    margin-top: -35px !important;
  }
  @include media-breakpoint-down(sm) {
    margin-top: -35px !important;
  }
}