// -----------------------------------------------------------------------------
// This file contains all styles related to the iFrame component.
// -----------------------------------------------------------------------------

.module--iframe {
    width: 100%;
    height: calculateRem(840px);
    iframe {
        width: 100%;
        height: 100%;
        border: 0;
        overflow: auto;
    }
}
