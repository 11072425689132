// -----------------------------------------------------------------------------
// This file contains all styles related to the card component.
// -----------------------------------------------------------------------------
.module--news-footer {
    ul {
        li {
            @include fontSize(20px);
            @include lineHeight(32px);  
            @include font-family-meta-normal();
            color: $primary;
            span {
                @include fontSize(20px);
                @include lineHeight(32px);  
                @include font-family-meta-bold();
                color: $primary;
                a {
                    color: $primary;
                }                
            }
            margin-right: $grid-gutter-width;
        }
    }
}

.module.module--news-footer + .module.module--pagination.news-pagination {
    margin-top: calculateRem(35px);  
}


