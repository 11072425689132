// -----------------------------------------------------------------------------
// This file contains all styles related to the card component.
// -----------------------------------------------------------------------------
.news-widget {
    padding-top: calculateRem(20px);
    .news-list {
        padding-bottom: calculateRem(27px);
        ul {
            margin-bottom: 0;
            li {
                .module--media {
                    width: calculateRem(105px); 
                }
                .module--text {
                    margin-top: calculateRem(15px);
                }
                .title, a, .title span {
                    //@include fontSize(16px);
                    //@include lineHeight(26px);  
                    //@include font-family-open-sans-semibold();
                    @include fontSize(20px);
                    @include lineHeight(32px);  
                    @include font-family-meta-bold();                    
                    color: $custom-primary;
                    &:hover {
                       // color: $primary;
                       color: $custom-primary;
                    }
                }
                & + li {
                    padding-top: calculateRem(15px);
                }

                &:before {
                    display: block;
                    width: auto;
                    content:'';
                    border-top: 1px solid $border-grey-light;
                    left: -($grid-gutter-width);
                    right: 0;
                    position: absolute;
                    @include media-breakpoint-down(md) {
                        left: 0;
                    }                    
                }
                &:first-child {
                    &:before {
                        display: none;
                    }
                }
            }
        }
    }
    .news-tags {
        padding-bottom: calculateRem(33px);
        ul {
            li {
                margin-right: calculateRem(6px);
                margin-bottom: calculateRem(6px);
                a {
                    @include fontSize(16px);
                    @include lineHeight(26px);  
                    //@include font-family-open-sans-semibold();
                    @include font-family-meta-semibold()
                    border: 1px solid $news-tags-border-color;
                    //color: $news-tags-text-color;
                    color: $primary;
                    padding: calculateRem(3px) calculateRem(12px) calculateRem(3px) calculateRem(12px);
                    &:hover, &.active {
                        color: $white;
                        //background-color: $custom-primary;
                        //border-color: $custom-primary;
                        background-color: $primary;
                        border-color: $primary;                        
                    }
                }

            }
        }
    }
    &:before {
        display: none !important;
    } 

    .module--media {
       a:hover {
            .figure {
                &:before {
                    background: radial-gradient(50% 50% at 50% 50%, rgba(0, 15, 27, 0) 0%, rgba(0, 15, 27, 0.6) 100%);     
                }
                img { 
                    max-width: auto !important;
                    height: 100% !important;   
                }
            }
        }   
    }
}