// -----------------------------------------------------------------------------
// This file contains all styles related to the uc-embedding-wrapper component.
// -----------------------------------------------------------------------------

.uc-embedding-wrapper {
  width: 80%;
  height: auto;
  max-height: 80%;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  position: absolute;
  padding: calculateRem(40px);
  text-align: center;
  overflow: auto;

  .uc-embedding-buttons {

    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    button.uc-embedding-more-info {
      border: 0;
      background: transparent;
      color: $body-color;
      margin-bottom: 1rem;
      order:1;
    }
    button.uc-embedding-accept {
      @include fontSize(20px); 
      @include font-family-meta-bold();
      color: $btn-primary;
      color: $white;
      background: $btn-primary;
      border: 0 !important;
      display: inline-block;
      width: auto;
      outline: 0 !important;
      box-shadow: none !important;
      border-radius: 0 !important;
      height: calculateRem(40px);
      padding-left: calculateRem(40px);
      padding-right: calculateRem(40px);
      @include media-breakpoint-down(md) {

      }
      order:3;
      &:hover {
        //transition: all 0.4s ease-in-out;        
      }  
    }
    &:after {
      content:"";
      display: block;
      width: 100%;
      order:2;
    }
  }
}