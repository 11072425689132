// -----------------------------------------------------------------------------
// This file contains all styles related to the card component.
// -----------------------------------------------------------------------------

//Newsteaser
.news-teaser-row > div {
    margin-bottom: $grid-gutter-width;
}

.news--title {
    @include media-breakpoint-up(lg) {
        position: absolute !important;
        top: -100px;
    }
}

.module--news-teaser {

    position: relative;
    height: 100%;
    width: 100%;
    //padding-bottom: $grid-gutter-width;
    overflow: hidden;

    .news-teaser-inner {
        position: relative;
        height: 100%;
        width: 100%;
        overflow: hidden;
    }
    .module.module--text {
        position: absolute;
        z-index: 10;
        height: auto;        
        width: 100%;
        padding: calculateRem(25px);
        top: 0;
        left:0;
        right: 0;
        bottom: 0;
        transition: $transition-base;  
        border-bottom: 4px solid transparent;
        overflow: hidden;

        > div {
            width: 100%;
        }

        .module--media---x  {
           a {
            display: block;
            width: 100%;
           } 
           picture {
            position: relative;
            width: 100%;
            height: 100%;
           }
        }

        .module-title {
            flex: 1 1 auto;
        }
        .module-pretitle {
            @include fontSize(14px);
            //@include lineHeight(27px);
            line-height: 1.2rem;
            //@include font-family-open-sans-semibold();
            @include font-family-meta-bold();
            color: $white;

            @include media-breakpoint-down(xl) {
                font-size: .7rem;
                @include lineHeight(19px);
            }
                          
        }   
        .title {
            //@include fontSize(22px);
            //@include lineHeight(35px);  
            //@include font-family-open-sans-bold();
            @include font-family-meta-bold();
            @include fontSize(24px);
            @include lineHeight(32px);  
            span {
                //@include font-family-open-sans-bold();
                @include font-family-meta-bold();
            }
            @include media-breakpoint-down(xl) {
                @include fontSize(18px);
                @include lineHeight(26px);  
            }
        } 
        .module-button {
            opacity: 0;
            transition: $transition-base;  
        }        
    }

    &:hover {
        .module.module--media {
            .figure {
                &:before {
                    // MEDIA
                    //background: radial-gradient(50% 50% at 50% 50%, rgba(#5C8BAC, 0.8) 0%, rgba(#5C8BAC, 0.8) 100%); 
                    //background: $blue;
                    background: $media-gradient-hover;
                    mix-blend-mode: multiply;
                 //   mix-blend-mode: multiply;
                }
                img {
                    //max-width: auto !important;
                    //height: 120% !important;   
                    transform: scale(1.2) ;
                }
            }            
        }
        .module.module--text {
            //border-bottom: 4px solid $border-secondary;
            border-bottom: 4px solid transparent;
            .module-button {
                opacity: 1;
            }            
        }
    }   
}


.sidebar .news-widget .module--news-teaser {
    .module.module--media:hover {
        .figure {
            img {
            //    height: 100% !important;   
            }
        }            
    }
}

.module--text.news--titel  {
    .module-title + .module-caption {
        margin-top: 20px;
    }
    .module-caption p {
        //color: #979797;
        @include fontSize(17px);
        @include lineHeight(27px);
    }
}

.news-detail {

    //padding-top: calculateRem(85px); > pagetitle + section-content
    padding-bottom: calculateRem(188px);
    .module + .module {
        margin-top: calculateRem(100px);
    }

    > .col-12 > .section-content > .container {
        padding-left: 0;
        padding-right: 0;
    }
}
