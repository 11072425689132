// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------

.site-header {

    background-color: $white;
    box-shadow: 0 4px 25px rgba(0,0,0,.15);
    @include media-breakpoint-down(md) {
        box-shadow: 0 4px 25px rgba(0,0,0,.05);
    }

    position: fixed;
    left: 0;
    top: 0;
    width: 100%;

    .header--top {
        height: 41px;
        padding: 0 0 0 0;
        border-bottom: 1px solid $border-grey-light;
        position: relative;
        z-index: 5;
        background-color: $white;
        
        a, p, span, li {
            text-decoration: none;
            color: $primary;
            @include fontSize(16px);
            @include lineHeight(20px); 
            @include media-breakpoint-down(md) {
                @include fontSize(14px);
            }
        }
        .contact   {
            padding: 10px 0 10px 0;
            span {
                padding-right: 5px;
            }

            ul {
                padding: 0 0;
                margin: 0 0;        
                li {
                    padding: 0 0;
                    margin: 0 0;
                    * {
                        white-space: nowrap;
                    }
                }
                li + li {
                    padding-left: 35px;
                    margin-left: 35px;
                    border-left: 1px solid $primary;
                    @include media-breakpoint-down(lg) {
                        padding-left: 15px;
                        margin-left: 15px;
                    }
                } 
            }
        }

        .search  {
            button {
                padding: 0 18px;
                margin: 0;
                border: 0;
                background: transparent !important;
                height: calculateRem(40px);
                @include media-breakpoint-down(sm) {
                    padding: 0 0 0 15px;
                    svg {
                        overflow: visible;
                    }
                }                

                span {
                    text-decoration: none;
                    color: $primary;
                    //@include fontSize(14px);
                    @include fontSize(16px);                     
                    @include lineHeight(40px);                     
                }
                svg {
                    //margin-top: -2px;
                    margin-top: 12px;
                    vertical-align: top;
                }
                span + span {
                    margin-left: calculateRem(8px);
                }
            }
        } 

        .location  {
            span {
                background-color: $bg-color-location;
                text-decoration: none;
                
                padding: 0 18px;
                
                white-space: nowrap;
                text-overflow: ellipsis;
                width: auto;
                overflow: hidden;
                color: $white;
                display: inline-block;
                max-width: 100%;
                
                @include fontSize(16px);
                @include lineHeight(40px);  
                //@include font-family-open-sans-bold();
                @include font-family-meta-bold();
                
                
                @include media-breakpoint-down(md) {
                    width: 100%;
                    text-align: center;
                }
            }    

            @include media-breakpoint-down(md) {
                width: 100vw;
                left: 50%;
                right: 50%;
                margin-left: -50vw !important;
                margin-right: -50vw !important;
                position: relative;
            }
        }

        .social  {
            padding: 10px 0 10px 0;
        }         
    }

    .social-media-icons {
        padding-left: calculateRem(60px);
        @include media-breakpoint-down(lg) {
            padding-left: 0;
            padding-top: calculateRem(60px);
            padding-bottom: calculateRem(100px);
        }
        li {
            padding: 0 0;
        }
        li + li {
            margin-left: 15px;
            @include media-breakpoint-down(lg) {
                margin-left: 30px;
            }
        }
        a  {
            color: $custom-primary;
            @include fontSize(15px);
            @include media-breakpoint-down(lg) {
                @include fontSize(20px);
            }
        }
    }

    a.navbar-brand {
        width: auto;
        padding: 0 0;
        z-index: 5;
        position: relative;
        @include media-breakpoint-up(lg) {
            position: absolute;
        }
        img {
            height: calculateRem(73px);
            width: auto;
            transition: $transition-base; 
            @include media-breakpoint-down(lg) {
                //width: calculateRem(87px);
                height: calculateRem(42px);
                width: auto;
            }                
            @include media-breakpoint-down(sm) {
                //width: calculateRem(74px);
                height: calculateRem(30px);
                width: auto;
            }         
        }        
    }

    .header--bottom {
        //background-color: $white;
        //box-shadow: 0 4px 25px rgba(0,0,0,.15);
        //@include media-breakpoint-down(md) {
        //    box-shadow: 0 4px 25px rgba(0,0,0,.05);
        //}
        //padding: calculateRem(46px) 0 calculateRem(46px) 0;
        //padding: calculateRem(46px) 0 calculateRem(0px) 0; // Navigation > ul 
        padding: 0 0;
        @include media-breakpoint-down(lg) {
            //padding: calculateRem(38px) 0 calculateRem(37px) 0;
            padding: calculateRem(31px) 0 calculateRem(30px) 0;
        }    
        @include media-breakpoint-down(sm) {
            padding: calculateRem(19px) 0 calculateRem(19px) 0;
        }

        .navbar-toggler {
            margin: 0 0 0 0;
            padding: 0 0 0 0;
            border: 0;
            border-radius: 0;
            box-shadow: none;
            outline: none;
            width: calculateRem(40px);
            height: calculateRem(27px);

            right: 0;
            position: absolute;
            margin-right: calc(var(--bs-gutter-x)*0.5);
            top: calculateRem(15px);
            z-index: 5;
            @include media-breakpoint-down(lg) {
                top: calculateRem(8px);
            }
            @include media-breakpoint-down(sm) {
                top: calculateRem(6px);
                margin-right: 0;
            }
            .navbar-toggler-icon {
                width: 100%;
                height: 100%;
                background-image: none;
                svg:first-child {
                    width: 100%;
                    height: auto;
                    display: block;
                }
                svg:last-child {
                    display: none;
                    height: 100%;
                    width: 100%;
                }                
            }
        }
    }
}

body.is--menu-open {
    overflow: hidden;
    .site-header .header--bottom .navbar-toggler-icon svg:first-child { display: none; }
    .site-header .header--bottom .navbar-toggler-icon svg:last-child { display: block; }
}



