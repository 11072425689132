// -----------------------------------------------------------------------------
// This file contains all styles related to the widget component.
// -----------------------------------------------------------------------------

.module--widget {

   position: relative;
   margin-top: 0 !important; 

   &:first-child {
        @include media-breakpoint-down(lg) {
            //margin-top: calculateRem(100px) !important;
        }
        @include media-breakpoint-down(md) {
            //margin-top: calculateRem(50px) !important;
        } 
    }   

    &:before {
        display: block;
        width: auto;
        content:'';
        border-top: 1px solid $border-grey-light;
        left: -($grid-gutter-width);
        right: 0;
        position: absolute;
        @include media-breakpoint-down(md) {
            left: 0;
        }
    }
    &:first-child {
        &:before {
            display: none;
        }
    }

    .title {
        //@include fontSize(18px);
        //@include lineHeight(31px);  
        //@include font-family-open-sans-bold();
        @include fontSize(20px);
        @include lineHeight(32px);  
        @include font-family-meta-bold();        
        margin-bottom: 0;
        hyphens: auto; 
    }

    .module--text {
        .module-title + .module-caption,
        .module-title + .module-media {
            margin-top: calculateRem(15px); 
        }
        
        .module-caption + .module-button,
        .module-title + .module-button {
            margin-top: calculateRem(0px);
        }
    }

    > .module--text .module-title:first-child {
        //padding-top: calculateRem(25px); 
        padding-top: calculateRem(45px);
    }
    > .module--text .module-button:first-child{
        padding-top: calculateRem(15px); 
    }
    > .module--text .module-button:last-child { 
        padding-bottom: calculateRem(15px); 
    }

    .module-caption {
        h3 {
            //@include fontSize(18px);
            //@include lineHeight(32px);  
            //@include font-family-open-sans-bold();
            @include fontSize(20px);
            @include lineHeight(32px);  
            @include font-family-meta-bold();             
            margin-bottom: calculateRem(15px); 
        }
        a[href^="mailto:"] {
            word-break: break-word;
        }
    }
    .module-media {
        .figure {
            display: inline-block;
            margin-right: calculateRem(10px); 
            &:last-child {
                margin-right: 0;   
            }
            img, svg {
                height: calculateRem(90px); 
                width: auto;

            }
        }
    }
    .module--media {
        &.is--gradient-r .figure img {
            height: 100%;
            transition: none;
        }    
    }

    &.bg--widget {
        background: $white;
        margin-left: -($grid-gutter-width);
        padding: 0 $grid-gutter-width $grid-gutter-width $grid-gutter-width;
        &:before {
            border-color: $primary;
            left: 0;
            top: 0;            
        }
        &.text-white {
            background: $bg-color-widget;
            &:before {
                border-color: $white;
                left: 0;
                top: 0;            
            }
            .module-caption a, p.email a, p.location a, p.phone a {
                //@include font-family-open-sans-bold();
                @include font-family-meta-bold();
            }            
        }
        @include media-breakpoint-down(md) {
            margin-left: 0;
        }        

        // standortseiten
        .title {
            @include fontSize(20px);
            @include lineHeight(32px);
        }
        .module-caption {
            h3, p {
                @include fontSize(20px);
                @include lineHeight(32px);  
            }
        }   
        .module--text {
            .module-caption + .module-button {
                margin-top: calculateRem(15px); 
            }            
        }
        .module-button--x {
            padding-bottom: 0 !important;
            .btn.btn-link {
                @include fontSize(17px);
                @include lineHeight(27px); 
                color: $white;
                span.arrow { 
                    width: 45px;
                    height: 35px;
                    > svg {
                        width: 100%;
                    }   
                }
                &.btn-default {
                    span + span.arrow { 
                        margin-left: 10px;
                    }
                }                
            }
        }
    }
}

//.sidebar > .module.module--widget:not(.bg--widget) > div > div
.sidebar > .module.module--widget:not(.bg--widget):first-child > div,
.sidebar > .module.module--widget:not(.bg--widget):first-child > div > div {
    padding-top: 0 !important; 
}

.bg--blue-gradient-2 .module.module--widget:first-child {
    margin-top: calculateRem(90px) !important; 
    @include media-breakpoint-down(md) {
        margin-top: 0 !important; 
    }
}