// -----------------------------------------------------------------------------
// This file contains all styles related to the caption component.
// -----------------------------------------------------------------------------

.module--text {
    position: relative;
    //overflow-x: hidden;

    &.layout--1 {
        .module-caption {
            margin-top: calculateRem(40px);
        }
        > .col-12:first-child > .module-caption:first-child {
            margin-top: 0;
        }
    }

    &.layout--2 {
        > div:nth-child(2)  {
            > .module:first-child {
                margin-top: calculateRem(40px);
            }
        }
        .module-title .title {
            @include media-breakpoint-up(md) {
                padding-right: calculateRem(15px);
            }
        }
        .module-caption {
            margin-top: calculateRem(-35px);
            @include media-breakpoint-down(md) {
                margin-top: calculateRem(40px);
            }             
        }
    }    


    &.layout--1, &.layout--2 {
        .module-caption:not([class*='mt--']) {
           // margin-top: calculateRem(40px);
        }
    }

    &.layout--3 {
        > div {
            display: flex !important;
        }
        .module-button {
            @include media-breakpoint-up(md) {
                margin-top: calculateRem(100px);
                margin-left: auto;
                align-self: center !important;
                text-align: right;
            }
        }
    }
    &.bg--blue-gradient {
        padding: $grid-gutter-width $grid-gutter-width $grid-gutter-width $grid-gutter-width;
        .module--text .module-caption:first-child {
            margin-top: 0;
        }
    }
}

.module--text > .module-pretitle.bg-title {
    @include media-breakpoint-down(md) {
        padding-left: calculateRem(30px) !important; 
    }  
}

.module-pretitle {
    &.bg-title {
        width: 100%;
        z-index: -1;
        position: absolute;
        top: 0; 
        padding-left: 8.33333%;

        > div {

            display: block;
            position: relative;
            width: 100%;
            height: calculateRem(200px); 
          //  overflow-x: hidden;
            padding-top: calculateRem(40px); 
            @include media-breakpoint-down(xl) {
                padding-top: calculateRem(60px);  
                height: calculateRem(150px);   
            }
            @include media-breakpoint-down(lg) {
                padding-top: calculateRem(70px); 
                height: calculateRem(140px);   
            } 
            @include media-breakpoint-down(md) {
                padding-top: calculateRem(50px); 
                height: calculateRem(140px);   
            }  
            @include media-breakpoint-down(sm) {
                padding-top: calculateRem(50px); 
                height: calculateRem(140px);   
            }             

            span {
                @include fontSize(175px);
                @include lineHeight(55px);  
                //@include font-family-open-sans-semibold();
                @include font-family-meta-semibold();
                color: $color-pretitle;        
                white-space: nowrap;
                opacity: 0.1;

                @include media-breakpoint-down(xl) {
                    @include fontSize(150px);
                    @include lineHeight(45px);   
                 }
                 @include media-breakpoint-down(lg) {
                    @include fontSize(130px);
                    @include lineHeight(35px);   
                }                 

                @include media-breakpoint-down(md) { 
                    @include fontSize(60px);
                    @include lineHeight(25px);                     
                }
            } 
        }
    } 

    &.layout--1, &.layout--2, &.layout--3 {
        .module-caption {
            margin-top: calculateRem(40px);
        }
        .module-button {
        }
    }
}

.module-pretitle.bg-title + .module-title {
    margin-top: 0; 
    padding-top: calculateRem(140px); 
    @include media-breakpoint-down(md) {
        padding-top: calculateRem(80px); 
    }
}

.module-pretitle + .module-title {
   margin-top: calculateRem(15px); 
}

.module-title + .module-caption {
    margin-top: calculateRem(40px);
}


.module-caption {
    //margin-top: calculateRem(40px);
}

.module-button {
    //margin-top: calculateRem(15px);
    // button -->
}

.module-caption {

    a {
        color: $custom-primary;
        text-decoration: none;
        @include font-family-meta-bold();

        &.secondary {
            color: $custom-secondary;
        }
    }   
    strong {
        @include font-family-meta-bold();
    }
    p, ol, ul {
        &:last-child {
            margin-bottom: 0;
        }
    }
    p, ol, ul, h2, h3, h4, h5, h6 {
        & + h2,
        & + h3,
        & + h4,
        & + h5,
        & + h6 {
            //margin-top: calculateRem(95px);
            margin-top: calculateRem(50px);
        }
    }
    p {
        &.location, &.email, &.phone {
            position: relative;
            padding-left: calculateRem(30px);
            margin-bottom: 0.25rem;
            span {
                display: inline-block;
                position: absolute;
                top: 0px;
                width: 16px;
                height: 32px;
                margin-left: -30px; 
                svg {
                    width: 100%;
                    height: 100%;
                }                
            }
            a {
                text-decoration: none;
                @include font-family-meta-normal();
                color: $body-color;
            }
        }

        &.location, &.email, &.phone {
            &:last-child {
                margin-bottom: 1rem;
            }
        }

        &.location {    
            //margin-bottom: 0;    
        }
        &.email {
            //margin-bottom: 0;
            span { width: 20px; }
        }     
        &.phone {  } 
        &.link {
            position: relative;
            padding-left: calculateRem(30px);            
        } 
    }

    ul {
        padding-left: 0;
        li {
            position: relative;
            list-style: none;
            padding-left: 28px; // accordion 37
            &:before {
                content: '';
                display: inline-block;
                position: absolute;
                top: 10px;
                width: 9px;
                height: 14px;
                margin-left: -28px; // accordion -37
                //background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIGlkPSJFYmVuZV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA5IDE3Ij48cGF0aCBkPSJtOSw4LjQ4YzAtMS45NTk5LTEuNjItMy40Nzk4LTEuNjItMy40Nzk4TDMuNDIuNjAwM0MyLjctLjIzOTYsMS42Mi0uMTU5Ni45MzYuNjAwM2wtLjkzNiwxLjA0LDQuMTA0LDQuNTU5OHMxLjI5NiwxLjIzOTksMS4yOTYsMi4zMTk5LTEuMjk2LDIuMzE5OS0xLjI5NiwyLjMxOTlMMCwxNS4zNTk3bC45MzYsMS4wNGMuNjg0Ljc1OTksMS43MjguODM5OSwyLjQ4NCwwbDMuOTYtNC4zOTk5czEuNjItMS41MTk5LDEuNjItMy41MTk4WiIgZmlsbD0iIzhkMDQ0ZiIvPjwvc3ZnPg==');
                background-image:  url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA5IDE3Ij48cGF0aCBmaWxsPSIjQ0QxNDMyIiBkPSJNOSA4LjQ4QzkgNi41MiA3LjM4IDUgNy4zOCA1TDMuNDIuNkMyLjctLjI0IDEuNjItLjE2LjkzNi42TDAgMS42NCA0LjEwNCA2LjJTNS40IDcuNDQgNS40IDguNTJzLTEuMjk2IDIuMzItMS4yOTYgMi4zMkwwIDE1LjM2bC45MzYgMS4wNGMuNjg0Ljc2IDEuNzI4Ljg0IDIuNDg0IDBMNy4zOCAxMlM5IDEwLjQ4IDkgOC40OHoiLz48L3N2Zz4=');
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
            & + li {
                margin-top: 15px;
            }
        } 
        @include media-breakpoint-down(md) {
            margin-bottom: 15px !important;   
        }
    }    
}

.section-content.bg--grey-dark .module--text.text-white .module-caption a {
    //color: $custom-secondary;
    color: $white;
}