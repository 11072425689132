//header logo
#site-header.is--sticky {
    a.navbar-brand {

        img {
            //width: calculateRem(80px);
            height: calculateRem(40px);
            @include media-breakpoint-down(lg) {
                //width: calculateRem(74px); 
                height: calculateRem(30px); 
            }
        }  
    }
}

#site-header.site-header.is--sticky .header--bottom {

    @include media-breakpoint-down(lg) {
        padding: calculateRem(19px) 0 calculateRem(19px) 0;
    }
    @include media-breakpoint-down(sm) {
        padding: calculateRem(19px) 0 calculateRem(19px) 0;
    }

    .navbar {
        @include media-breakpoint-up(lg) {
            min-height: calculateRem(80px);
            ul li.nav-item {
                padding-top: calculateRem(20px); // mouseover
                padding-bottom: calculateRem(20px);
            }
        }  
    } 

    .navbar-toggler {
        top: calculateRem(6px);
        @include media-breakpoint-down(sm) {
            top: calculateRem(6px);
        }
    }
}



