// -----------------------------------------------------------------------------
// This file contains all styles related to the Gallery component.
// -----------------------------------------------------------------------------

.module--gallery {

    .container + .container-fullwidth {
        margin-top: calculateRem(80px);
        @include media-breakpoint-down(md) {
            margin-top: calculateRem(25px);
        }
    }

    .module-button {
        position: absolute;
        right: 0;
        bottom: 0;
        background-color: $primary;
        z-index: 5;
        padding: calculateRem(50px) calculateRem(80px);
        display: inline-block;
        margin-top: 0;
        @include media-breakpoint-down(xl) {
            padding: calculateRem(30px) calculateRem(40px);
            padding: 30px 40px;
        }
        @include media-breakpoint-down(lg) {
            padding: calculateRem(50px) calculateRem(80px);
            padding: 50px 80px;
        }        
        @include media-breakpoint-down(md) {
            padding: calculateRem(30px) calculateRem(40px);
            padding: 30px 40px;
        }        
        @include media-breakpoint-down(sm) {
            padding: calculateRem(30px) calculateRem(30px);
            width: 100%;
            position: relative;
            a {
                display: block !important;
                text-align: right !important;                
            }
        }
    }
    .module--media {
        height: 100%;
        width: 100%;
        flex-direction: column;
        position: relative;
        display: flex;
        padding-top: calculateRem(25px);

        .figure {
            height: 100%;
            width: 100%;
            img {
                height: 100%;
                width: 100%;
                -o-object-fit: cover;
                object-fit: cover;           
            }
        }
    }
    .module--text-image {
        height: 100%;
        @include media-breakpoint-down(lg) {
            height: auto;
        }
    }

    .row  {
        margin-right: calculateRem(-12.5px);
        margin-left: calculateRem(-12.5px);
    }
    .row > * {
        padding-right: calculateRem(12.5px);
        padding-left: calculateRem(12.5px);
    }
    
    .col:first-child {
        flex: 0 0 auto;  
        width: 20%;  
        @include media-breakpoint-down(lg) {
            width: 100%;
        }  
    }

    .col:last-child {
        flex: 0 0 auto;
        width: 40%;
        @include media-breakpoint-down(lg) {
            width: 100%;
        }
    }
}