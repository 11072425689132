.tx-indexedsearch-form {

    .module--text {
        margin-top: calculateRem(50px);
        margin-bottom: calculateRem(20px);
    }
    
    .module-button {
        margin-top: 0;
        &.extended-search {
            .btn.btn-link span + span.arrow, .module-button .btn.btn-link span.arrow + span {
                margin-left: 0;
            }             
        }

        &.extended-search {
            margin-top: calculateRem(5px);
        }
    }
}

.tx-indexedsearch-res {
    
    .module--text {
        margin-top: calculateRem(80px);
        margin-bottom: calculateRem(10px);
        .module-title {

        }
        .module-caption {
            margin-top: calculateRem(20px);
        }
        
        .module-button {
            margin-top: calculateRem(15px);
        }      
    }
}

.tx-indexedsearch-res + .tx-indexedsearch-pagination {
    margin-top: calculateRem(90px);
}

.searchbox-label-link {
    //@include fontSize(20px);
    //@include lineHeight(22px);  
    //@include font-family-open-sans-bold();
    @include font-family-meta-bold();
    color: $btn-primary;
    @include media-breakpoint-down(sm) {
    //    @include fontSize(18px);
    }
}