// -----------------------------------------------------------------------------
// This file contains all styles related to the timeline component.
// -----------------------------------------------------------------------------

.module--timeline {

    .timeline-item {
        
        & + .timeline-item  {
        //    padding-bottom: calculateRem(75px);
        }
        .module-title {
            margin-bottom: calculateRem(25px);
            .title {
                color: $custom-primary;
            }
        }
        .module-caption {
            //margin-top: calculateRem(12px);
            margin-top: 0;
            padding-bottom: calculateRem(75px);

            h3 {
                @include media-breakpoint-down(xl) {
                    @include fontSize(25px);
                    @include lineHeight(35px); 
                }                
            }
        }
        .marker {
            position: relative;
            &:before {
                content:'';
                display: block;
                position: absolute;
                border-left: 1px solid $border-grey-light-timeline;
                height: 100%;
                bottom: 0;
                left: calculateRem(27px);
                z-index: -1;
                @include media-breakpoint-down(xl) {
                    left: calculateRem(25px);
                }
                @include media-breakpoint-down(lg) {
                    left: calculateRem(23px);
                }
                @include media-breakpoint-down(md) {
                    left: calculateRem(20px);
                }                
            }

            svg {
                background-color: $body-bg;
                vertical-align: top;
                width: 100%;
                height: 25px;
                overflow: visible;
                margin-top: 5px;
                @include media-breakpoint-down(lg) {
                    margin-top: 7px;
                }                
            }
            @include media-breakpoint-up(md) {
                margin-left: -($grid-gutter-width);
            }         
        }
        &:first-child {
            .marker:before {
                height: 97%;
            }
        }
        &:last-child {
            .marker:before {
                height: 5%;
                bottom: auto;
                top: 0;
            }
            .module-caption {
                padding-bottom: calculateRem(0px);
            }            
        }         
    }
}