// -----------------------------------------------------------------------------
// This file contains all styles related to the card, teaser, download component.
// -----------------------------------------------------------------------------

.module--card {
    margin-bottom: calculateRem(50px);
    .module-caption {
        margin-top: calculateRem(20px);

        h2 {
            @include fontSize(20px);
            @include lineHeight(32px);  
            @include font-family-meta-bold();
            a {
                color: $primary;
            }            
        }
        p a {
            @include fontSize(20px);
            @include lineHeight(32px);  
            //@include font-family-open-sans-semibold();
            @include font-family-meta-semibold();
            color: $custom-primary;
        }
        * {
            margin-bottom: 0;
        }
    }
    .module-button {
        margin-top: calculateRem(20px);
        span.arrow > svg,
        span.arrow > span > svg {
            path {
                //fill: $btn-secondary;
                fill: $custom-primary;
            } 
        }         
    } 

    .module-media {
        .figure {
            margin-bottom: 0;
        }
        &.bg--img {
            position: relative;
            padding-top: 83%;
            .figure {
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 100%;

                picture {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    img {
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translateX(-50%) translateY(-50%);   
                        max-height: 80%;      
                        width: auto;
                    }
                }
                > span {
                    position: absolute;
                    left: 0;
                    top: 0;
                    z-index: -1;
                    height: 100%;
                    width: 100%;
                    background: linear-gradient(87.96deg,#004780 19.07%,#5c8bac);
                    svg {
                        display: block;
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        max-width: 100%;
                        max-height: 100%;
                    }
                }            
            }
        }
    }
    .module-media + .d-flex {
       // margin-top: 1rem;
    }

    &.download {
        .module-media {
            background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIGlkPSJFYmVuZV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzOTMgMjY4Ij48cGF0aCBkPSJtMjc5LjY4OSwxNDUuMTUyYy03OS45MzMsOTcuODA0LTE0OC44NTMsMTExLjg1NC0xNzMuMzIyLDEwNi42NTNDMTcuNjQ4NSwyMzMuNzAxLTUwLjYzNTksMTIwLjczOS02MC43NDk5LDY3LjUzNTJjLTEwLjExNC01My4yMDM0LDE1LjUwNDYtMTIyLjA1ODgsOTUuMzQwOS0xNDAuNjg2NCw3OS44MzYtMTguNjI3NiwxODIuNzM0LDM0LjE2MTEsMTkyLjkwMSw0MC40MzgyLDEwLjE2Nyw2LjI3NzEsMTUyLjExMyw1NS42MDk5LDUyLjE5NywxNzcuODY1WiIgZmlsbD0icmdiYSg5MiwxMzksMTcyLC4xNSkiLz48cGF0aCBkPSJtMjIwLjE4LTE5LjM0MjdjMzYuMDg0LDExNC43NzkzLDExLjIzOCwxNzcuMDAxNy01LjY5NiwxOTMuNzY0Ny02MC43OTksNjEuMTc2LTE4Ni40NDQxLDU2LjQ4Ny0yMzQuMDk0MiwzNi43MjQtNDcuNjUwMi0xOS43NjMtODkuMDgwOC03Ni4xNjEtNjIuMTcyNy0xNDkuNDY4Qy01NC44NzQ0LTExLjYyOTMsNDAuOTU1MS02NS45NjA3LDUxLjI2ODctNzAuNzc0MnMxMjMuODA2My05Mi4wNDI4LDE2OC45MTEzLDUxLjQzMTVaIiBmaWxsPSJub25lIiBzdHJva2U9IiMwMDQ4NzUiLz48L3N2Zz4='), linear-gradient(44deg, #004780 0%, #5C8BAC 100%);
            background-position: 0 0;
            background-repeat: no-repeat;
            text-align: center;
            padding-top: calculateRem(61px);
            padding-bottom: calculateRem(61px);
            position: relative;

            .figure {
                margin-bottom: 0;
                min-height: calculateRem(205px);
            }
        }
    }
}

.cards .module--card  {
    margin-bottom: 1.5rem;
}