// -----------------------------------------------------------------------------
// This file contains all styles related to the text-image component.
// -----------------------------------------------------------------------------
.module--widget {
    &.author {
        padding-bottom: calculateRem(40px);
        &:before {
            display: none;
        }

        .module--text {
            padding-top: calculateRem(40px); 
        }
        .module-caption {
            p {
                margin-bottom: 0;
                &.small {
                    @include fontSize(12px);
                    @include lineHeight(25px);
                }
            }
        }

        .module-contact {
            padding-top: calculateRem(15px);
            ul {
                li {
                    margin-right: calculateRem(5px);
                    &:last-child {
                        margin-right: 0;
                    }
                    a {
                        background: $custom-primary;
                        color: $white;
                        width: calculateRem(28px);
                        height: calculateRem(28px);
                        border-radius: 50rem;
                        text-align: center;
                        display: inline-block;
                        position: relative;
                        
                        svg {
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            transform: translateX(-50%) translateY(-50%);
                           
                            path {
                                fill: $white;
                            } 
                        }
                    }
                }
            }
        }
    }
}
