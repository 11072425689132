// -----------------------------------------------------------------------------
// This file contains all styles related to the widget component.
// -----------------------------------------------------------------------------

.module--listmenu {

    ul {
        margin-bottom: 0;
        li {
            width: 100%;
            display: block;
            border-top: 1px solid $border-grey-light;
            border-bottom: 1px solid $border-grey-light;
            margin-top: -1px;

            a {
                @include fontSize(20px);
                @include lineHeight(32px);  
                @include font-family-meta-semibold();
                color: $btn-primary;
                text-decoration: none;
                display: block;
                
                > span:first-child {
                    margin: calculateRem(14px) calculateRem(20px);
                    max-width: 80%;
                    @include media-breakpoint-down(md) {
                        max-width: 100%;
                        display: block;
                    }
                }
                > span:last-child {
                    margin: calculateRem(9px) calculateRem(20px);

                    > span:first-child:not(.arrow) {
                        margin: calculateRem(5px) calculateRem(17px) calculateRem(5px) calculateRem(20px);
                        white-space: nowrap;
                    }
                }                
            }
        }
    }

    &.download {
        ul li a {
            > span:first-child {
                @include font-family-meta-bold();
                color: $primary;                
            }
            > span:last-child {
                margin: calculateRem(9px) calculateRem(20px);

                > span:first-child:not(.arrow) {
                    margin: calculateRem(5px) calculateRem(17px) calculateRem(5px) calculateRem(20px);
                }
            } 
        }
        .arrow  {
            transform: rotate(90deg);
        }
    }

 
    span.arrow > svg,
    span.arrow > span > svg {
        path {
            fill: $custom-primary;
        } 
    }         

}

.module.module--text + .module.module--listmenu {
    margin-top: calculateRem(40px);
}

.module-title + .module-listmenu {
    margin-top: calculateRem(55px);
}