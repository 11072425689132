.sidebar {

    &.sidebar-sticky {
        position: fixed;
        right: calculateRem(25px);
        //top: calculateRem(530px);
        top: 50%;
        z-index: 100;

        @include media-breakpoint-down(md) {
           // z-index: 10000;
        }
        @include media-breakpoint-down(sm) {
            top: 25%;
        }

        ul {
            li {
                & + li {
                    margin-top: 5px;
                }                 
                a {
                    text-decoration: none;
                    //text-transform: uppercase;
                    @include fontSize(20px);
                    @include lineHeight(22px);  
                    //@include font-family-open-sans-semibold();
                    @include font-family-meta-bold();
                    color: $custom-primary;
                    display: flex !important;
                    text-align: right;
                    margin-left: auto !important;
                    justify-content: flex-end;
                    position: relative;
                    > span {
                        position: relative;
                        @include lineHeight(44px);
    
                        &.text {
                            display: none;
                            opacity: 0;
                            transition: $transition-base;
                            position: absolute;
                            right: 54px;
                            white-space: nowrap;
                            pointer-events: none;
                        }
                        &.beat {
                            opacity: 0;
                            position: absolute;
                            right: 54px;
                            white-space: nowrap;
                            pointer-events: none;
                        }
                        &.icon {
                            width: calculateRem(44px);
                            height: calculateRem(44px);
                            text-align: center;
                            border-radius: 50%;
                            display: inline-block;
                            background-color: $custom-primary;
    
                            @include media-breakpoint-down(md) {
                                opacity: 0.6;
                            }
    
                            svg {
                                width: auto;
                                position: absolute;
                                left: 50%;
                                top: 50%;
                                transform: translateX(-50%) translateY(-50%);                                
                            }
                        }                     
                    }   
                }    
                      
                


                
                &:hover {
                    span.text {
                        display: inline-block;
                        opacity: 1;
                    }
                    span.beat {
                        display: none;
                        opacity: 0;
                    }  
                    span.icon {
                        animation: none;
                    }                                        
                }
            }
        }  
        
        .xxx {
            @include media-breakpoint-down(md) {
                position: fixed;
                width: 100vw;
                height: 100vh;
                right: 0;
                top: 0;
                background-color: #FF0;
                z-index: 10000;

                overflow-y: auto;
                
                display: flex;
                ul {
                    width: 100%;
                    margin-top: auto;
                    padding: 50px 25px;
                    li {
                        width: 100%;
                        a > span.text {
                            display: block;
                            opacity: 1;
                        }
                    }
                }
            } 
        }
    }
}

@keyframes beat {
    0% { transform: scale(1); }
    10% { transform: scale(1.2); }
    20% { transform: scale(1); }
}

@keyframes fade-in {
    0% { opacity: 0; }
    10% { opacity: 1; }
    20% { opacity: 0; }
}

body.career {
    .sidebar {
        &.sidebar-sticky {
            ul {
                li {
                    &.beat {
                        span.beat {
                            //animation: fade-in 1.5s linear 5s forwards;
                            animation: fade-in 6s ease 5s infinite;                       
                        }                    
                        span.icon {
                            //animation: beat 1.5s linear 5s forwards;
                            animation: beat 6s ease 5s infinite;  
                        }
                    }
                }
            }
        }
    }
}