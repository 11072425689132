// -----------------------------------------------------------------------------
// This file contains all styles related to the caption component.
// -----------------------------------------------------------------------------
.module--accordion {
    
    //width: 100%;

    &.column--count--2 {
        //column-count: 2;
    }

    .accordion-item {
        border-left: 0;
        border-right: 0;
        margin-top: -1px;
    }

    .accordion-header {
        .accordion-button {
            //@include fontSize(18px);
            //@include lineHeight(32px);  
            //@include font-family-open-sans-bold();
            @include fontSize(20px);
            @include lineHeight(32px);  
            @include font-family-meta-bold();            
            padding: calculateRem(14px) calculateRem(10px) calculateRem(14px) calculateRem(80px);
            background-color: $custom-primary;
            color: $white;
            border-top: 1px solid transparent;
            border-bottom: 1px solid transparent;
            transition: $transition-base;
            box-shadow: none; 

            &:focus-visible {
                box-shadow: $focus-box-shadow; 
            }          

            &.collapsed {
                background-color: transparent;
                color: $primary;
                border-top: 1px solid $border-grey-light;
                border-bottom: 1px solid $border-grey-light;

                &:before {
                    //background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIGlkPSJFYmVuZV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMS4zNzc0IDIxLjM3NzQiPjxyZWN0IHg9IjkuOTQ3MyIgeT0iMCIgd2lkdGg9IjIiIGhlaWdodD0iMjEuMzc3NCIgcng9IjEiIHJ5PSIxIiBmaWxsPSIjOGQwNDRmIi8+PHBhdGggZD0ibTEsMTEuODkyMUMuNDQ3OCwxMS44OTIxLjAwMDUsMTEuNDQ0OCwwLDEwLjg5MjZzLjQ0NzMtMSwuOTk5NS0xLjAwMDVsMTkuMzc3OS0uMDA4OGgwYy41NTE4LDAsMSwuNDQ3MywxLC45OTk1cy0uNDQ3MywxLTEsMS4wMDA1bC0xOS4zNzcuMDA4OGgtLjAwMDVaIiBmaWxsPSIjOGQwNDRmIi8+PC9zdmc+');
                    background:  url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMS4zNzcgMjEuMzc3Ij48cmVjdCB3aWR0aD0iMiIgaGVpZ2h0PSIyMS4zNzciIHg9IjkuOTQ3IiBmaWxsPSIjQ0QxNDMyIiByeD0iMSIgcnk9IjEiLz48cGF0aCBmaWxsPSIjQ0QxNDMyIiBkPSJNMSAxMS44OTJhMSAxIDAgMCAxIDAtMmwxOS4zNzctLjAwOWExIDEgMCAxIDEgMCAyTDEgMTEuODkzeiIvPjwvc3ZnPg==');
                }
            }

            &:before {
                position: absolute;
                left: 30px;
                top: 22px;
                transition: none;
                display: block;
                content:'';
                width: 20px;
                height: 20px;
                background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIGlkPSJFYmVuZV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMS4zNzc0IDIuMDA4OCI+PHBhdGggZD0ibTEsMi4wMDg4Qy40NDc4LDIuMDA4OC4wMDA1LDEuNTYxNSwwLDEuMDA5M1MuNDQ3My4wMDkzLjk5OTUuMDA4OGwxOS4zNzc5LS4wMDg4aDBjLjU1MTgsMCwxLC40NDczLDEsLjk5OTVzLS40NDczLDEtMSwxLjAwMDVsLTE5LjM3Ny4wMDg4aC0uMDAwNVoiIGZpbGw9IiNmZmYiLz48L3N2Zz4=');;
                background-size: 100% auto;
                background-repeat: no-repeat;
                background-position: center center;
            }
            &:not(.collapsed):before {
             
            }
        }

    }
    .accordion-body {
        padding: calculateRem(40px) calculateRem(30px) calculateRem(40px) calculateRem(30px);
        @include media-breakpoint-down(md) {
            padding: calculateRem(25px) calculateRem(30px) calculateRem(25px) calculateRem(30px);
        }
        .module--text .module-caption {
            margin-top: 0;
        }

        .module--media + .module--text {
           margin-top: calculateRem(30px);
        }       
    }


    // Standorte 
    &.location{
        .accordion-body {
            padding: calculateRem(10px) calculateRem(30px) calculateRem(25px) calculateRem(30px);
            @include media-breakpoint-down(md) {
                padding: calculateRem(10px) calculateRem(30px) calculateRem(25px) calculateRem(30px);
            }
        }
        .module-title {
            h2 {
                //@include fontSize(18px);
                //@include lineHeight(28px);  
                //@include font-family-open-sans-bold();
                @include fontSize(20px);
                @include lineHeight(32px);  
                @include font-family-meta-bold();                  
                margin-bottom: calculateRem(22px);
                color:$body-color;
            }  
        }
        .module-caption {                   
            p {
                margin-bottom: 0.25rem;
            }
        }        
        .module--text .module-caption:first-child {
            margin-top: 0;
        }
        .module +  .module {
            margin-top: calculateRem(22px);
        } 
    }
}

// Leistungen
.module--text + .module--accordion {
    margin-top: calculateRem(55px);
}
